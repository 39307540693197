import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import { styles } from '../Styles/Styles';
import { ButtonList, CategoryTitle, DropdownInput, InputList } from '../components/PostProperty/PostPropertyComp';
import { NavLink, useNavigate } from 'react-router-dom';
import TopCItiesFilter from '../components/TopCItiesFilter';
import Footer from '../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { setBuyerRegData, setPostPropertyFormData, setuser } from '../Redux/reducer/User';
import PhoneInput from 'react-phone-input-2';
import { ToastContainer, toast } from 'react-toastify';
import { UseApi } from '../ApiConf';
import loader from '../assets/Icons/loader.gif';
import Auth from '../components/Auth';

const countries = ['India', 'Singapore', 'UAE', 'United States'];
// const indianStates = [
//     'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana',
//     'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
//     'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana',
//     'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Chandigarh',
//     'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep', 'Delhi', 'Puducherry'
// ];

const Cities = ['Kolkata', 'Pune', 'Delhi']

// const inputs = [
//     { name: 'Interested Country', placeholder: 'Your Country', state: 'country', star: false, dropdownData: countries },
//     { name: 'Interested State', placeholder: 'Your State', state: 'State', star: false, dropdownData: indianStates },
//     { name: 'Interested City', placeholder: 'Your City', state: 'city', star: false, dropdownData: Cities },
//     { name: 'Interested Locality', placeholder: 'Your Locality', state: 'locality', star: false, dropdownData: ['locality1'] },
//     { name: 'Interested Property', placeholder: 'Your Property', state: 'Property', star: false, dropdownData: ['property1'] },
//     { name: 'Min Budget', placeholder: 'Min Budget', state: 'minbudget', star: false, dropdownData: ['Rs. 1 Lac', 'Rs. 5 Lac', 'Rs. 10 Lac'] },
//     { name: 'Max Budget', placeholder: 'Max Budget', state: 'Maxbudget', star: false, dropdownData: ['Rs. 1 Lac', 'Rs. 5 Lac', 'Rs. 10 Lac'] },
//     { name: 'Interested in', placeholder: 'Interested in', state: 'interestedIn', star: false, dropdownData: ['1 BHK', '2 BHK', '3 BHK', '4 BHK+'] },
//     { name: 'Facing Preference', placeholder: 'Facing Preference', state: 'Facing Preference', star: false, dropdownData: ['North', 'East', 'South', 'West', 'North East', 'North West', 'South West', 'South East'] },
//     { name: 'Want to Buy', placeholder: 'Want to Buy', state: 'Want to Buy', star: false, dropdownData: ['Immediate', '1-3 Months', '3-6 Months', '6-12 Months'] },
//     { name: 'Floor Preference', placeholder: 'Floor Preference', state: 'Floor Preference', star: false, dropdownData: ['0-5', '6-10', '>10'] },
// ]
const Iam = [
    { label: 'Buyer', value: 'buyer' },
    { label: 'Tenant', value: 'tenent' }
]
const BuyerRegistration = () => {

    const [animation, setAnimation] = useState(false);
    // const [generalInfoValus, setGeneralInfoValus] = useState({ name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual/Owner', Otp: '' });
    // const [mobileVerification, setMobileVerification] = useState(false);
    const [inputErrorStatus, setInputErrorStatus] = useState({ name: '', email: '', countryCode: '', mobileNum: '', Otp: '', password: '' });
    // const [actualOtp, setActualOtp] = useState('');
    const { postPropertyFormData, buyerRegistrationData, login_status, userData, filterMenus } = useSelector(state => state.User);
    const [addressData, setAddressData] = useState({ countries: [], states: [], cities: [], localities: [], subLocalities: [], projects: [] });
    const [inputFields, setInputFields] = useState({ countries: [], furnishingItem: [], length: [], propertyType: [], status: [], unit: [], ageProperty: [] });
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { FetchData, Request } = UseApi();
    const [inputErrorState, setInputErrState] = useState({});
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [showLoginPopup, setShowLoginPopup] = useState(false);

    useEffect(() => {
        setAnimation(true);
        // console.log('buyer registraation....');
        if (!login_status) {
            dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, name: '', email: '', countryCode: '+91', mobileNum: '', mobileVerification: false, completed: false, Iam: 'buyer' } }))
        } else {
            navigate('/');
        }
    }, [login_status])

    useEffect(() => {
        getInputOptions('1');
    }, [login_status])

    useEffect(() => {
        if (login_status) {
            getInputOptions('2', 'state_list', buyerRegistrationData.country, 'oldLocation');
        }
    }, [buyerRegistrationData.country, login_status]);

    useEffect(() => {
        if (login_status) {
            getInputOptions('2', 'city_list', buyerRegistrationData.state, 'oldLocation');
        }
    }, [buyerRegistrationData.state, login_status]);

    useEffect(() => {
        if (login_status) {
            getInputOptions('2', 'locality_list', buyerRegistrationData.city, 'oldLocation');
        }
        // if (buyerRegistrationData.city != 'Other') {
        //     getInputOptions('2', 'project_society', buyerRegistrationData.city, 'oldLocation');
        // }
    }, [buyerRegistrationData.city, login_status]);

    useEffect(() => {
        if (login_status) {
            getInputOptions('2', 'sublocality_list', buyerRegistrationData.locality, 'oldLocation');
        }
    }, [buyerRegistrationData.locality, login_status]);


    const getInputOptions = async (type, get_list, currnet_val, locType) => {
        if (!login_status) return;
        setLoading(true);
        if (!currnet_val && type == '2') return;
        // if (checkInputValidation()) return;
        let token = localStorage.getItem('token');
        let params = {
            type: type,
            token: token,
            get_list: get_list ? get_list : '',
            currnet_val: (currnet_val || currnet_val != 'Other') ? currnet_val : ''
        }
        let data;
        try {
            data = await Request('form-related', 'POST', params);
        } catch (err) {
            console.log(err);
        }
        console.log('data....', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                if (type == '1') {
                    // setPropertyTypes(data.data?.propertyType);
                    // setAddressData(pre => ({ ...pre, countries: data.data?.countries }));
                    setInputFields(pre => ({ ...pre, ...data.data }));
                }
                if (type == '2') {
                    if (get_list == 'state_list') {
                        setAddressData(pre => ({ ...pre, states: data.data, cities: [], localities: [], subLocalities: [], projects: [] }));
                        if (locType != 'oldLocation') {
                            dispatch(setBuyerRegData({
                                ...buyerRegistrationData,
                                state: '', stateName: '', city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', projectName: '', societyName: '', otherProject: ''
                            }));
                        }
                    }
                    if (get_list == 'city_list') {
                        setAddressData(pre => ({ ...pre, cities: data.data, localities: [], subLocalities: [], projects: [] }));
                        if (locType != 'oldLocation') {
                            dispatch(setBuyerRegData({
                                ...buyerRegistrationData,
                                city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', projectName: '', societyName: '', otherProject: ''
                            }));
                        }
                    }
                    if (get_list == 'locality_list') {
                        setAddressData(pre => ({ ...pre, localities: data.data, subLocalities: [] }));
                        if (locType != 'oldLocation') {
                            dispatch(setBuyerRegData({
                                ...buyerRegistrationData,
                                locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '',
                            }));
                        }
                    }
                    if (get_list == 'sublocality_list') {
                        setAddressData(pre => ({ ...pre, subLocalities: data.data }));
                        if (locType != 'oldLocation') {
                            dispatch(setBuyerRegData({
                                ...buyerRegistrationData,
                                subLocality: '', otherSubLocality: ''
                            }));
                        }
                    }
                    if (get_list == 'project_society') {
                        setAddressData(pre => ({ ...pre, projects: data.data }));
                        if (locType != 'oldLocation') {
                            dispatch(setBuyerRegData({
                                ...buyerRegistrationData,
                                projectName: '', societyName: '', otherProject: ''
                            }));
                        }
                    }
                }
                if (data.message) {
                    toast(data.message, { type: 'success' });
                }
                setLoading(false);
            } else {
                toast(data.message, { type: 'error' });
                setLoading(false);
            }
        }
    }


    const signUp = async () => {
        if (checkInputValidation()) return;
        setLoading(true);
        let inputdata = {
            user_type: postPropertyFormData.generalInfo.Iam,
            reg_user_name: postPropertyFormData.generalInfo.name,
            reg_email: postPropertyFormData.generalInfo.email,
            reg_user_password: password,
            country_code: postPropertyFormData.generalInfo.countryCode,
            contact_number: postPropertyFormData.generalInfo.mobileNum,
            is_normal_registration: '0',
            // action_user: 'generate_otp',
            is_buyer: 'Yes'
            // mobile_otp:''
            // accept_term: signupInputDatas.term,
        };
        console.log('inputdat....', inputdata);
        let data;
        try {
            data = await Request('registration', 'POST', inputdata);
            // data = await FetchData('registration', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }
        console.log('reg data2...', data);
        if (data?.statusCode == 200 && data?.success) {
            if (data.token) {
                // dispatch(setuser(data));
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileVerification: false } }));
                localStorage.setItem('token', data.token);
                // if (data.data) {
                //     dispatch(setuser(data.data));
                // }
            } else {
                setUserId(data?.user_id);
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileVerification: true } }))
            }
            toast(data.message, { type: 'success' });
            // navigate('/');
        } else if (data?.registered) {
            if (data.verified) {
                toast(data.message, { type: 'error' });
                setShowLoginPopup(true);
            } else {
                resendOtp(data?.user_id);
                setUserId(data?.user_id);
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileVerification: true } }))
            }
        }
        setLoading(false);
    }
    const submitGeneralInfo = async () => {
        if (checkGeneralInfoValidation()) return;
        setLoading(true);
        let inputdata = {
            token: userData?.jwt_tocken,
            Country: buyerRegistrationData.country,
            State: buyerRegistrationData.state,
            City: buyerRegistrationData.city,
            Locality: buyerRegistrationData.locality,
            InterestedProperty: buyerRegistrationData.interestedProperty,
            MinBudget: buyerRegistrationData.minBudget,
            MaxBudget: buyerRegistrationData.maxBudget,
            InterestedIn: buyerRegistrationData.InterestedIn,
            FacingPreference: buyerRegistrationData.facingPref,
            WantToBuy: buyerRegistrationData.wantToBuy,
            FloorPreference: buyerRegistrationData.floorPref
        };
        console.log('inputdat....', inputdata);
        let data;
        try {
            data = await Request('buyer-register', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }
        console.log('reg data...', data);
        if (data?.statusCode == 200 && data?.success) {
            toast(data.message, { type: 'success' });
        }
        setLoading(false);
    }

    const checkGeneralInfoValidation = () => {
        let error = false;
        if (!buyerRegistrationData.country) {
            setInputErrState(pre => ({ ...pre, country: 'Please select a country !' }));
            error = true;
        }
        if (!buyerRegistrationData.state) {
            setInputErrState(pre => ({ ...pre, state: 'Please select a state !' }));
            error = true;
        }
        if (!buyerRegistrationData.city) {
            setInputErrState(pre => ({ ...pre, city: 'Please select a city !' }));
            error = true;
        }
        if (!buyerRegistrationData.locality) {
            setInputErrState(pre => ({ ...pre, locality: 'Please select a locality !' }));
            error = true;
        }
        if (!buyerRegistrationData.interestedProperty) {
            setInputErrState(pre => ({ ...pre, interestedProperty: 'Please select your interested property !' }));
            error = true;
        }
        if (!buyerRegistrationData.minBudget) {
            setInputErrState(pre => ({ ...pre, minBudget: 'Please select minimum budget !' }));
            error = true;
        }
        if (!buyerRegistrationData.maxBudget) {
            setInputErrState(pre => ({ ...pre, maxBudget: 'Please select maximum budget !' }));
            error = true;
        }

        return error;
    }

    const verifyMobile = async () => {
        setLoading(true);
        let inputdata = {
            type: '2',
            otp: postPropertyFormData.generalInfo.Otp,
            mobile: postPropertyFormData.generalInfo.mobileNum,
            user_id: userId
        }
        let data;
        try {
            data = await Request('otp', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            if (err?.message) {
                setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            }
        }
        console.log('otp data...', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, completed: true, mobileVerification: false } }));
                if (data.data) {
                    dispatch(setuser(data.data));
                } else {
                    dispatch(setuser({}));
                }
                if (data.token) {
                    localStorage.setItem('token', data.token);
                    // localStorage.setItem('isLoggedIn', true);
                }
                toast(data.message, { type: 'success' });
                setLoading(false);

            } else {
                toast(data.message, { type: 'error' });
                setLoading(false);
            }

        } else {
            console.log('error,,', data);
            // setInputErrorStatus(pre => ({ ...pre, Otp: data.message }));
        }
    }

    const resendOtp = async (UserId) => {
        setLoading(false);
        let inputdata = {
            type: '1',
            mobile: postPropertyFormData.generalInfo.mobileNum,
            user_id: UserId ? UserId : userId
        }
        let data;
        try {
            data = await Request('otp', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            if (err?.message) {
                setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            }
        }
        if (data?.statusCode == 200) {
            if (data.success) {
                toast(data.message, { type: 'success' });
            } else {
                toast(data.message, { type: 'error' });
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
        setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
    }
    const onCloseLoginPopup = () => {
        setShowLoginPopup(false);
    }

    const checkInputValidation = () => {
        let error = false;
        if (!/^[a-zA-Z\s'-]+$/.test(postPropertyFormData.generalInfo.name)) {
            setInputErrorStatus(pre => ({ ...pre, name: 'Please write a valid name !' }));
            error = true;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(postPropertyFormData.generalInfo.email)) {
            setInputErrorStatus(pre => ({ ...pre, email: 'Please write a valid email !' }));
            error = true;
        }

        if (!/^\d{7,15}$/.test(postPropertyFormData.generalInfo.mobileNum)) {
            setInputErrorStatus(pre => ({ ...pre, mobileNum: 'Please write a valid contact number!' }));
            error = true;
        }
        if (password.length < 6) {
            console.log('password.length...', password.length)
            setInputErrorStatus(pre => ({ ...pre, password: 'Please enter at least 6 characters!' }));
            error = true;
        }
        // console.log('error....',error);
        return error;
    }
    return (
        <div>
            <Header />
            {/* <ToastContainer toastClassName={{}} /> */}
            <div className={'mt-24 px-2 container mx-auto'}>
                <div className='px-2 sm:px-5 mb-16'>
                    <CategoryTitle title={'General Information :'} icon={'fa-regular fa-user mt-1'} />
                    {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                        <img alt="Please wait.." title="Please wait.." src={loader} />
                    </div>}
                </div>
                {!postPropertyFormData.generalInfo.completed && !login_status && <div className={styles.formCard + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')}>
                    {!postPropertyFormData.generalInfo.mobileVerification ?
                        <>
                            <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-8'>
                                <div>
                                    <span className={styles.textMedium}>Name<span className='text-red-500'>*</span></span>
                                    <input required placeholder='Enter Your Name' className={styles.input + 'mt-1'}
                                        value={postPropertyFormData.generalInfo.name}
                                        onChange={(e) => {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, name: e.target.value } }))
                                            if (/^[a-zA-Z\s'-]+$/.test(e.target.value) && inputErrorStatus.name) {
                                                setInputErrorStatus(pre => ({ ...pre, name: '' }));
                                            }
                                        }}
                                    />
                                    {inputErrorStatus.name && <p className='text-red-600 text-sm'>{inputErrorStatus.name}</p>}
                                </div>
                                <div>
                                    <span className={styles.textMedium}>Email<span className='text-red-500'>*</span></span>
                                    <input required placeholder='Enter Your Email' className={styles.input + 'mt-1'}
                                        value={postPropertyFormData.generalInfo.email}
                                        onChange={(e) => {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, email: e.target.value } }))
                                            if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value) && inputErrorStatus.email) {
                                                setInputErrorStatus(pre => ({ ...pre, email: '' }));
                                            }
                                        }}
                                    />
                                    {inputErrorStatus.email && <p className='text-red-600 text-sm'>{inputErrorStatus.email}</p>}
                                </div>
                                <div>
                                    <div className='flex gap-2'>
                                        <div className='w-[30%]'>
                                            <span className={styles.textMedium}>Mobile<span className='text-red-500'>*</span></span>
                                            <PhoneInput
                                                country={'in'}
                                                containerStyle={{ width: '100%', marginTop: '5px' }}
                                                inputStyle={{ width: '100%', height: '42px', borderRadius: '0px' }}
                                                placeholder='Phone*'
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true,
                                                    readOnly: true
                                                }}
                                                value={postPropertyFormData.generalInfo.countryCode}
                                                onClick={() => {
                                                    if (postPropertyFormData.generalInfo.countryCode == '') {
                                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, countryCode: '+91' } }))
                                                    }
                                                }}
                                                onChange={(val) => dispatch(
                                                    setPostPropertyFormData({
                                                        ...postPropertyFormData,
                                                        generalInfo: { ...postPropertyFormData.generalInfo, countryCode: val, completed: false }
                                                    }))}
                                            />
                                        </div>
                                        <div className='w-[70%] mt-[25px]'>
                                            {/* <span className={styles.textMedium}>Mobile<span className='text-red-500'>*</span></span> */}
                                            <input required placeholder='Enter Your Mobile No' className={styles.input + 'mt-1'}
                                                value={postPropertyFormData.generalInfo.mobileNum}
                                                onChange={(e) => {
                                                    if (/^[0-9]*$/.test(e.target.value)) {
                                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileNum: e.target.value, completed: false } }))
                                                        if (/^\d{7,15}$/.test(e.target.value) && inputErrorStatus.mobileNum) {
                                                            setInputErrorStatus(pre => ({ ...pre, mobileNum: '' }));
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {inputErrorStatus.mobileNum && <p className='text-red-600 text-sm'>{inputErrorStatus.mobileNum}</p>}
                                </div>
                            </div>
                            <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-8'>
                                <div>
                                    <span className={styles.textMedium}>Password<span className='text-red-500'>*</span></span>
                                    <input required placeholder='Enter password' className={styles.input + 'mt-1'}
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            if (inputErrorStatus.password) {
                                                setInputErrorStatus(pre => ({ ...pre, password: '' }));
                                            }
                                        }}
                                    />
                                    {inputErrorStatus.password && <p className='text-red-600 text-sm'>{inputErrorStatus.password}</p>}
                                </div>
                            </div>
                            <div className='mt-8'>
                                <ButtonList title={'I am'} btnNames={Iam} required={true}
                                    value={postPropertyFormData.generalInfo.Iam}
                                    onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, Iam: item } }))}
                                />
                            </div>
                            <div className='mt-10 flex justify-center'>
                                <button className={styles.formBtn + styles.btnBlackHover} onClick={signUp}>Continue</button>
                            </div>
                        </>
                        :
                        <div>
                            <div className='w-full max-w-[400px]'>
                                <span className={styles.textMedium}>Verify Your Mobile<span className='text-red-500'>*</span></span>
                                <input required placeholder='Enter OTP received on your mobile' className={styles.input + 'mt-1'}
                                    value={postPropertyFormData.generalInfo.Otp}
                                    onChange={(e) => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, Otp: e.target.value } }))
                                        if (inputErrorStatus.Otp) {
                                            setInputErrorStatus(pre => ({ ...pre, Otp: '' }));
                                        }
                                    }}
                                />
                                {inputErrorStatus.Otp && <p className='text-red-600 text-sm'>{inputErrorStatus.Otp}</p>}
                            </div>
                        </div>
                    }

                    {postPropertyFormData.generalInfo.mobileVerification && <div className='mt-8 flex justify-center gap-2'>
                        {/* {postPropertyFormData.generalInfo.mobileVerification && <button className={styles.formBtn + styles.btnBlackHover}
                            onClick={() => dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { ...postPropertyFormData.generalInfo, mobileVerification: false } }))}>
                            Back</button>} */}
                        <button className={styles.formBtn + styles.btnBlackHover} onClick={verifyMobile}>Verify OTP</button>
                        <button className={styles.formBtn + styles.btnBlackHover} onClick={resendOtp}>Resend OTP</button>
                    </div>}
                </div>}
                {(postPropertyFormData.generalInfo.completed || login_status) && <div className={styles.formCard + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')}>
                    <div>
                        {/* <InputList inputs={inputs} classname={'mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'} /> */}
                        <div className='mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
                            <DropdownInput title={'Interested Country'} options={inputFields.countries} placeholder={'Select Country'} required={true}
                                error={inputErrorState.country}
                                value={buyerRegistrationData.country}
                                onChange={(e) => {
                                    // dispatch(setBuyerRegData({
                                    //     ...buyerRegistrationData,
                                    //     subLocality: '', otherSubLocality: ''
                                    // }));
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, country: e.target.value }));
                                    if (inputErrorState.country) { setInputErrState(pre => ({ ...pre, country: '' })) };
                                }}
                            />
                            <DropdownInput title={'Interested State'} options={addressData.states} placeholder={'Select State'} required={true}
                                error={inputErrorState.state}
                                value={buyerRegistrationData.state}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, state: e.target.value }));
                                    if (inputErrorState.state) { setInputErrState(pre => ({ ...pre, state: '' })) };
                                }}
                            />
                            <DropdownInput title={'Interested City'} options={addressData.cities} placeholder={'Select City'} required={true}
                                error={inputErrorState.city}
                                value={buyerRegistrationData.city}
                                onChange={(e) => {
                                    console.log('e.target.value...', e.target.value);
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, city: e.target.value }));
                                    if (inputErrorState.city) { setInputErrState(pre => ({ ...pre, city: '' })) };
                                }}
                            />
                            <DropdownInput title={'Interested Locality'} options={addressData.localities} placeholder={'Your Locality'} required={true}
                                error={inputErrorState.locality}
                                value={buyerRegistrationData.locality}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, locality: e.target.value }));
                                    if (inputErrorState.locality) { setInputErrState(pre => ({ ...pre, locality: '' })) };
                                }}
                            />
                            <DropdownInput title={'Interested Property'} options={inputFields.propertyType} placeholder={'Interested Property'} required={true}
                                error={inputErrorState.interestedProperty}
                                value={buyerRegistrationData.interestedProperty}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, interestedProperty: e.target.value }));
                                    if (inputErrorState.interestedProperty) { setInputErrState(pre => ({ ...pre, interestedProperty: '' })) };
                                }}
                            />
                            <DropdownInput title={'Min Budget'} options={filterMenus?.saleBudget} placeholder={'Min Budget'} required={true}
                                error={inputErrorState.minBudget}
                                value={buyerRegistrationData.minBudget}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, minBudget: e.target.value }));
                                    if (inputErrorState.minBudget) { setInputErrState(pre => ({ ...pre, minBudget: '' })) };
                                }}
                            />
                            <DropdownInput title={'Max Budget'} options={filterMenus?.saleBudget} placeholder={'Max Budget'} required={true}
                                error={inputErrorState.maxBudget}
                                value={buyerRegistrationData.maxBudget}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, maxBudget: e.target.value }));
                                    if (inputErrorState.maxBudget) { setInputErrState(pre => ({ ...pre, maxBudget: '' })) };
                                }}
                            />
                            <DropdownInput title={'Interested In'} options={['1 BHK', '2 BHK', '3 BHK', '4 BHK+']}
                                placeholder={'Interested In'}
                                value={buyerRegistrationData.InterestedIn}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, InterestedIn: e.target.value }));
                                    if (inputErrorState.InterestedIn) { setInputErrState(pre => ({ ...pre, InterestedIn: '' })) };
                                }}
                            />

                            <DropdownInput title={'Facing Preference'} options={['North', 'East', 'South', 'West', 'North East', 'North West', 'South West', 'South East']}
                                placeholder={'Facing Preference'}
                                value={buyerRegistrationData.facingPref}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, facingPref: e.target.value }));
                                    if (inputErrorState.facingPref) { setInputErrState(pre => ({ ...pre, facingPref: '' })) };
                                }}
                            />
                            <DropdownInput title={'Want to Buy'} options={['Immediate', '1-3 Months', '3-6 Months', '6-12 Months']} placeholder={'Want to Buy'}
                                value={buyerRegistrationData.wantToBuy}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, wantToBuy: e.target.value }));
                                    if (inputErrorState.wantToBuy) { setInputErrState(pre => ({ ...pre, wantToBuy: '' })) };
                                }}
                            />
                            <DropdownInput title={'Floor Preference'} options={['0-5', '6-10', '> 10']} placeholder={'Floor Preference'}
                                value={buyerRegistrationData.floorPref}
                                onChange={(e) => {
                                    dispatch(setBuyerRegData({ ...buyerRegistrationData, floorPref: e.target.value }));
                                    if (inputErrorState.floorPref) { setInputErrState(pre => ({ ...pre, floorPref: '' })) };
                                }}
                            />
                        </div>
                        <div className='mt-10 flex gap-5'>
                            <NavLink to={'/'}>
                                <button className={styles.formBtn}>Back</button>
                            </NavLink>
                            <button type='submit' className={styles.formBtn} onClick={submitGeneralInfo} >Submit</button>
                        </div>
                    </div>
                </div>}
                {showLoginPopup && <Auth onClose={onCloseLoginPopup} />}
            </div>

            <TopCItiesFilter />
            <Footer />
        </div>

    );
}

export default BuyerRegistration;
