export const styles = {
    dropdown:'text-white text-sm sm:text-base hover:text-gray-300 focus:outline-none mr-7 flex ',
    dropdownBtn:'text-white px-2 sm:px-4 py-1 text-sm sm:text-base justify-between flex border-green-500 rounded-md ml-4 border-[1px] hover:bg-gray-700 ',
    btn:'flex item-center justify-center text-gray-800 text-sm sm:text-base border-gray-300 rounded-md py-1 px-2 border-[1.5px] ',
    labelBtn:'py-[1px] px-[5px] text-white text-sm ',
    btnFull:'text-white text-base sm:text-lg w-full py-1 rounded ',
    btnBorderLess:'flex text-black text-sm md:text-base px-2 py-1 rounded-md ml-0 hover:bg-gray-200 ',
    dropdownMenu:'absolute hidden group-hover:block z-50 bg-white text-gray-800 top-8 pb-0 border-gray-300 border-[1px] w-[100%] shadow-sm text-sm ',
    dropdownContainer:'absolute hidden z-50 bg-white p-2 pt-2 w-[180px] overflow-auto max-h-[380px] sm:w-[400px] sm:ml-0 text-gray-800 top-8 border-gray-300 border-[1px] ',
    dropdownItem:'p-2 text-sm border-b-[1px] border-gray-100 flex hover:cursor-pointer hover:bg-gray-100 ',
    dropdownIcon:'fa-solid fa-chevron-down ml-[1px] text-gray-600 text-xs ',
    input:'border-[1px] border-gray-300 pl-2 py-2 w-[100%] focus:outline-none focus:border-gray-400 placeholder:text-gray-400 text-sm sm:text-base ',
    title1:'px-2 text-center text-xl sm:text-2xl font-semibold ',
    // title2:'text-xl md:text-2xl font-semibold ',
    title2:'text-xl md:text-[1.3rem] font-semibold ',
    title3:'text-base md:text-lg lg:text-xl font-semibold ',
    title4:'text-base md:text-lg font-semibold ',
    title5:'text-base md:text-[1.05rem] font-semibold tracking-wider text-gray-700 ',
    title2Bold:'text-xl font-bold tracking-wide',
    textMedium:'text-sm sm:text-base ',
    textLarge:'text-base sm:text-lg ',
    paragraph:'text-sm sm:text-base text-left py-5 px-2 md:px-8 ',
    // arrowLeft:'absolute md:left-1 rounded-full bg-black opacity-20 hover:opacity-60 p-1 '
    arrowBg:'absolute rounded-full flex justify-center items-center bg-white shadow-md p-5 ',

    lightBorder:'border-b-[1px] border-b-gray-300 ',
    // textSmall:'text-sm sm:text-base ',

    //postpropertyStyles
    postpropTitle:'text-orange-500 text-center py-2 sm:text-lg font-semibold bg-gray-50 ',
    formCard:' border-[1px] border-gray-100 rounded-md shadow-xl bg-white p-2 px-2 sm:px-5 my-5 pb-5 ',
    btnBlackHover:' hover:bg-white hover:text-black border-[1px] hover:border-black duration-500 ',
    formBtn:'hover:bg-gray-600 bg-gray-700 text-white text-sm sm:text-base px-4 py-1 rounded ',
    paginationBtn:'bg-gray-100 rounded-md font-semibold px-4 py-3 hover:bg-gray-700 hover:text-white duration-500 text-sm tracking-wider ',
    noDataFound:'bg-red-600 font-semibold text-white text-sm rounded-md mt-5 py-3 pl-4 w-full ',
    loading:' animate-spin duration-1000 text-gray-500 text-2xl sm:text-3xl '

}