import React, { useState } from 'react';
import { styles } from '../Styles/Styles';
import { NavLink } from 'react-router-dom';
const SiteMapProperty = ({ title, item }) => {
    const [cityName, setCityName] = useState(item.cityName.toUpperCase());
    const [showMore, setShowMore] = useState({ bedroom: false, price: false, furnish: false });
    return (
        <div className='mt-5'>

            <p className={styles.title3}>{item.cityName}</p>
            <div className='mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 item-center w-full'>
                <div className='w-full'>
                    <p className='mb-2 font-semibold prose'>PROPERTY BY BEDROOMS IN {cityName}</p>
                    <div className='max-h-[300px] overflow-y-auto custom-scrollbar pr-1'>
                        {item?.bedroom?.map((itm, ind) => {
                            return (
                                <>
                                    {(ind < 7 || showMore.bedroom) && <NavLink to={itm.url} key={ind}><p className='text-sm my-2 hover:opacity-80 hover:cursor-pointer text-gray-600'>{itm.title}</p></NavLink>}
                                </>
                            )
                        })}
                        {item.bedroom?.length == 0 && <p className='mt-10 prose text-sm'>No Results Found!</p>}
                    </div>
                    {item.bedroom?.length > 7 && <button
                        onClick={() => setShowMore(pre => ({ ...pre, bedroom: !showMore.bedroom }))}
                        className='underline font-semibold text-gray-500 hover:text-gray-600'>
                        {showMore.bedroom ? 'View Less' : 'View More'}
                    </button>}
                </div>
                <div className='w-full'>
                    <p className='mb-2 font-semibold prose'>PROPERTY PRICES IN {cityName}</p>
                    <div className='max-h-[300px] overflow-y-auto custom-scrollbar pr-1'>
                        {item?.price?.map((itm, ind) => {
                            return (
                                <>
                                    {(ind < 7 || showMore.price) && <NavLink to={itm.url} key={ind} ><p className='text-sm my-2 hover:opacity-80 hover:cursor-pointer text-gray-600'>{itm.title}</p></NavLink>}
                                </>
                            )
                        })}
                        {item.price?.length == 0 && <p className='mt-10 prose text-sm'>No Results Found!</p>}
                    </div>
                    {item?.price?.length > 7 && <button
                        onClick={() => setShowMore(pre => ({ ...pre, price: !showMore.price }))}
                        className='underline font-semibold text-gray-500 hover:text-gray-600'>
                        {showMore.price ? 'View Less' : 'View More'}
                    </button>}
                </div>
                <div className='w-full'>
                    <p className='mb-2 font-semibold prose'>PROPERTY BY FURNISHING IN {cityName}</p>
                    <div className='max-h-[300px] overflow-y-auto custom-scrollbar pr-1'>
                        {item?.furnish?.map((itm, ind) => {
                            return (
                                <>
                                    {(ind < 7 || showMore.furnish) && <NavLink to={itm.url} key={ind} ><p className='text-sm my-2 hover:opacity-80 hover:cursor-pointer text-gray-600'>{itm.title}</p></NavLink>}
                                </>)
                        })}
                        {item.furnish?.length == 0 && <p className='mt-10 prose text-center text-sm'>No Results Found!</p>}
                    </div>
                    {item?.furnish?.length > 7 && <button
                        onClick={() => setShowMore(pre => ({ ...pre, furnish: !showMore.furnish }))}
                        className='underline font-semibold text-gray-500 hover:text-gray-600'>
                        {showMore.furnish ? 'View Less' : 'View More'}
                    </button>}
                </div>
            </div>
        </div>

    );
}

export default SiteMapProperty;
