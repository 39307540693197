import React, { useEffect, useRef, useState } from 'react';
import { CategoryTitle } from './PostPropertyComp';
import { styles } from '../../Styles/Styles';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { UseApi } from '../../ApiConf';
import { toast } from 'react-toastify';
import { setPostPropertyFormData } from '../../Redux/reducer/User';

const Gallery = () => {
  const [animation, setAnimation] = useState(false);
  // const [files, setFiles] = useState([]);
  // const [selectedFile,setSelectedFile] = useState(null);
  // const [imgSrcs, setImgSrcs] = useState([]);
  const inputRef = useRef(null);
  const [checked, setChecked] = useState(false);
  const { postPropertyFormData } = useSelector(state => state.User);
  const [inputErrStatus, setInputErrorStatus] = useState({});
  const { Request } = UseApi();
  const dispatch = useDispatch();


  useEffect(() => {
    setAnimation(true);
  }, [])

  const handleDeleteFile = (index) => {

    if (postPropertyFormData.buyRentGallary.imageFiles.length === 1) {
      inputRef.current.value = null
    }
    // setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
    // setImgSrcs([...imgSrcs.slice(0, index), ...imgSrcs.slice(index + 1)]);
    dispatch(setPostPropertyFormData({
      ...postPropertyFormData,
      buyRentGallary: {
        ...postPropertyFormData.buyRentGallary,
        imgSrcs: [...postPropertyFormData.buyRentGallary.imgSrcs.slice(0, index), ...postPropertyFormData.buyRentGallary.imgSrcs.slice(index + 1)],
        imageFiles: [...postPropertyFormData.buyRentGallary.imageFiles.slice(0, index), ...postPropertyFormData.buyRentGallary.imageFiles.slice(index + 1)],
      }
    }));
  }

  const deleteImage = async (url) => {
    let urlArr = url.split('/');
    let token = localStorage.getItem('token');
    let inputdata = {
      type: '6',
      token: token,
      property_id: postPropertyFormData.currPropId,
      page_type_property: 'property',
      action: 'delete_image',
      image_name: urlArr[urlArr.length - 1]
    }
    let data;
    try {
      data = await Request('submit-property', 'POST', inputdata);
    } catch (err) {
      console.log('error...', err);
    }
    if (data?.success) {
      toast(data.message, { type: 'success' });
      console.log('data?.success...', data?.success);
      // if (i == files.length - 1) {
      // dispatch(setPostPropertyFormData({
      //   ...postPropertyFormData,
      //   buyRentGallary: {
      //     ...postPropertyFormData.buyRentGallary,
      //     imgSrcs: [...arr],
      //     imageFiles: [...postPropertyFormData.buyRentGallary.imageFiles, ...files]
      //   }
      // }));
      // }
    } else if (data?.tokenStatus) {
      toast(data.message, { type: 'error' });
    }
  }


  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      let newFiles = e.target.files;
      if ((postPropertyFormData.buyRentGallary.imageFiles.length + newFiles.length + postPropertyFormData.buyRentGallary.imageUrls.length) > 15) {
        alert('Can not upload more than 15 images !');
        return;
      }
      // setFiles([...files, ...newFiles]);

      let arr = [...postPropertyFormData.buyRentGallary.imgSrcs];
      for (let i = 0; i < newFiles.length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          arr = [...arr, reader.result];
          uploadPhoto(newFiles, i, arr);
        }
        reader.readAsDataURL(newFiles[i]);
      }
    }
  }

  const uploadPhoto = async (files, i, arr) => {
    let token = localStorage.getItem('token');
    let inputdata = {
      type: '4',
      token: token,
      property_id: postPropertyFormData.currPropId,
      page_type_property: 'property',
      userImage: files[i]
    }
    console.log('files...', files[i]);
    let data;
    try {
      data = await Request('submit-property', 'POST', inputdata);
    } catch (err) {
      console.log('error...', err);
    }
    if (data?.success) {
      toast(data.message, { type: 'success' });
      console.log('data?.success...', data?.success);
      // if (i == files.length - 1) {
      dispatch(setPostPropertyFormData({
        ...postPropertyFormData,
        buyRentGallary: {
          ...postPropertyFormData.buyRentGallary,
          imgSrcs: [...arr],
          imageFiles: [...postPropertyFormData.buyRentGallary.imageFiles, ...files]
        }
      }));
      // }
    } else if (data?.tokenStatus) {
      toast(data.message, { type: 'error' });
    }
  }

  const uploadVideo = async (file) => {
    let token = localStorage.getItem('token');
    let inputdata = {
      type: '5',
      token: token,
      property_id: postPropertyFormData.currPropId,
      page_type_property: 'property',
      user_video: file
    }
    console.log('file...', file);
    let data;
    try {
      data = await Request('submit-property', 'POST', inputdata);
    } catch (err) {
      console.log('error...', err);
    }
    if (data?.success) {
      toast(data.message, { type: 'success' });
      console.log('data?.success...', data?.success);
      let url = URL.createObjectURL(file);
      dispatch(setPostPropertyFormData({ ...postPropertyFormData, buyRentGallary: { ...postPropertyFormData.buyRentGallary, video: url } }));
    }
  }

  return (
    <form className={'mt-16 ' + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')}>
      {
        // postPropertyFormData.nearByPlace.completed ?
        postPropertyFormData.amenities.completed ?
          <>
            <div>
              <CategoryTitle title={'Gallery : Allow max photo upload - 15'} icon={'fa-regular fa-file-image'} />
              <div className='border-dashed border-gray-600 border-[1px]'>
                <input accept='image/*' multiple ref={inputRef} type='file' onChange={handleFileChange} className='w-[80%] absolute opacity-0 py-14 cursor-pointer' />
                <div className='text-gray-400 flex flex-col items-center justify-center py-10 text-cente'>
                  <i class="fa-solid fa-cloud-arrow-up text-4xl"></i>
                  <p className=''>Click here to upload your images. Max file size of 5 MB is allowed.</p>
                </div>
              </div>
              <div className='mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-8 gap-3 sm:gap-5'>

                {postPropertyFormData.buyRentGallary.imageUrls.map((item, index) => {
                  return (
                    <div key={index} className='relative'>
                      <img src={item} alt="Selected File" className='h-28 w-full' />
                      <i
                        onClick={() => deleteImage(item)}
                        class="fa-solid fa-circle-xmark absolute -top-2 text-red-600 bg-white rounded-full -right-2 cursor-pointer"></i>
                    </div>
                  )
                })}
                {postPropertyFormData.buyRentGallary.imgSrcs.map((item, index) => {
                  return (
                    <div key={index} className='relative'>
                      <img src={item} alt="Selected File" className='h-28 w-full' />
                      <i onClick={() => handleDeleteFile(index)}
                        class="fa-solid fa-circle-xmark absolute -top-2 text-red-600 bg-white rounded-full -right-2 cursor-pointer"></i>
                    </div>
                  )
                })}
              </div>
              <div>
                <div className='flex mt-5 text-gray-700 p-3 -mx-[1.6%]'>
                  <i class="fa-solid fa-video text-lg mt-[2px] mr-2"></i>
                  <span className={styles.title3}>Video:</span>
                </div>
                <input className={styles.input} type='file' accept='video/*'
                  // onClick={(e) => e.target.value == null}
                  onChange={(e) => {
                    // let url = URL.createObjectURL(e.target.files[0]);
                    // dispatch(setPostPropertyFormData({ ...postPropertyFormData, buyRentGallary: { ...postPropertyFormData.buyRentGallary, video: url } }));
                    uploadVideo(e.target.files[0]);
                    if (inputErrStatus.video) { setInputErrorStatus(pre => ({ ...pre, video: '' })) };
                  }}
                />
                <div className={'text-gray-500 text-sm mt-1'}>
                  <p>Allowed File type: MP4</p>
                  <p>Max Size Limit: 50 MB</p>
                </div>
                 {postPropertyFormData.buyRentGallary.video && <video autoPlay controls width="" className='w-full md:w-1/2 mt-5 rounded-lg'>
                    <source
                      // src="https://www.truehomes24.com/assets/dynamic/unit-configuration/6636/5dd451239ce95933b2be48ec8a0aa057.mp4"
                      src={postPropertyFormData.buyRentGallary.video}
                      type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>}
              </div>
              <div className='mt-5'>
                <label className='flex'>
                  <input type='checkbox' checked={checked} onChange={() => setChecked(!checked)} className='size-5 mt-2 flex-none mr-2' />
                  <p>I am the owner/I have the authority to post this property. I agree not to provide incorrect property information or state as a discrimination performance.
                    In case the information does not comply with truehomes24, truehomes24 has the right to edit remove the property from their site.</p>
                </label>
              </div>
              <div className='mt-5 flex gap-5'>
                <button className={styles.formBtn}>Back</button>
                <NavLink to={'/membership'}>
                  <button type='submit' disabled={!checked} className={styles.formBtn + (checked ? '' : ' bg-gray-500')}>Save & Payment</button>
                </NavLink>
              </div>
            </div>
          </>
          :
          <div className={(animation ? 'transition-opacity opacity-100 duration-500' : 'opacity-0') + ' bg-red-600 rounded text-white p-2 font-semibold'}>
            Please submit {!postPropertyFormData.generalInfo.completed ? 'general information' : !postPropertyFormData.propertyInfo.completed ? 'property information' :
              // !postPropertyFormData.amenities.completed ? 'aminities' : 'nearByPlace'}  first
              'aminities'}  first
          </div>
      }


    </form>
  );
}

export default Gallery;
