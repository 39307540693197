import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import BgImage from '../../assets/images/buildersBg.jpg'
import { NavLink, useLocation } from 'react-router-dom';
import { styles } from '../../Styles/Styles';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import RightListCard from '../../components/RightListCard';
import { UseApi } from '../../ApiConf';
import { useSelector } from 'react-redux';
import loader from '../../assets/Icons/loader.gif';
import MetaTags from '../../components/MetaTags';



const BlogDetails = () => {
    const [loading, setLoading] = useState(true);
    const { FetchData, Request } = UseApi();
    let routePath = useLocation();
    const [blogDetails, setBlogDetails] = useState(null);
    const [meta,setMeta] = useState(null);
    const [recentBlogs, setRecentBlogs] = useState([]);
    const [recentAddedProp, setRecentAddedProp] = useState([]);
    const { currLocation } = useSelector(state => state.User);

    useEffect(() => {
        let pathArr = routePath.pathname.split('/');
        if (routePath.pathname.includes('blogs') && pathArr.length == 3) {
            getBlogDetailsData(pathArr[2]);
        }
        getBlogsData();
        // getRecentlyAddedProp();
    }, []);

    const getBlogDetailsData = async (title) => {
        setLoading(true);
        let data;
        try {
            data = await FetchData(`blogs?is_blog_details=1&alias=${title}`, 'GET');
        } catch (err) {
            console.log(err);
        }
        console.log('blogs details data...',data);
        if (data?.success) {
            setBlogDetails(data.data);
            setMeta(data.meta);
            setRecentAddedProp(data?.data?.recentlyAdded);
        }
        setLoading(false);
    }
    const getBlogsData = async () => {
        let data;
        try {
            data = await FetchData(`blogs?page=1&limit=5&city=${currLocation.code}`, 'GET');
        } catch (err) {
            console.log(err);
        }
        if (data) {
            console.log('blogs data..', data);
            setRecentBlogs(data?.content);
        }
    }

    const getRecentlyAddedProp = async () => {
        let data;
        try {
            data = await FetchData(`property-list?property_status=&recently_added=1&city=${currLocation.code ? currLocation.code : '10383'}&page=1&limit=5`, 'GET');
        } catch (err) {
            console.log(err);
        }
        if (data) {
            console.log('recent added data..', data);
            setRecentAddedProp(data.property);
            // setRecentBlogs(data?.content);
        }
    }


    return (
        <div className='container mx-auto'>
            <Header />
            {meta && <MetaTags metaData={meta}/>}
            <div >
                <div className=' fixed left-0 top-0 h-screen w-screen'>
                    <img alt='' src={BgImage} className='h-full' />
                </div>
                {loading && <div className="fixed top-[300px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='fixed left-0 h-full mb-2 w-full bg-black bg-opacity-50 overflow-y-scroll'>
                    <div>
                        <div className='mt-[150px] h-[100px] z-[200] text-center text-white tracking-widest'>
                            <p className={'text-center text-2xl sm:text-3xl text-white font-semibold tracking-wider md:text-3xl'}>Blog Details</p>
                            <div className='mt-5 text-xs lg:text-sm'>
                                <NavLink to={'/'}>HOME</NavLink> / <NavLink to={'/blogs'}>BLOGS</NavLink> / <span>BLOG DETAILS</span>
                            </div>
                        </div>
                        <div className='mt-10 min-h-[500px] bg-gray-50'>
                            <div className='lg:flex gap-10'>
                                <div className='w-full lg:w-[65%] lg:bg-white py-5'>
                                    <div className='pt-10 px-2 sm:px-[10%]'>
                                        <p className={styles.title3 + 'my-2'}>{blogDetails?.NewsTitle}</p>
                                        <div className='my-8 flex justify-between'>
                                            <span>{blogDetails?.About}</span>
                                            <span>Posted On: {blogDetails?.CreatedOn}</span>
                                        </div>
                                        <div className='w-full'>
                                            <img src={blogDetails?.CoverImage} className='h-[400px] w-full' />
                                        </div>
                                        <div className='mt-5 prose min-w-full text-justify' dangerouslySetInnerHTML={{ __html: blogDetails?.NewsDescription }}></div>
                                    </div>
                                </div>
                                <div className='w-full lg:bg-gray-50 lg:px-2 lg:w-[28%]'>
                                    <div className='px-2 sm:px-[10%] lg:px-5'>
                                        <RightListCard title={'Recent Blog'} data={recentBlogs} />
                                        {/* <RightListCard title={'Recently Added Property'} data={Data} /> */}
                                        <RightListCard title={'Recently Added Property'} data={recentAddedProp} />
                                    </div>

                                </div>
                            </div>
                            <TopCItiesFilter />
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogDetails;
