import React, { useState } from 'react';
import { styles } from '../Styles/Styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { UseApi } from '../ApiConf';
import { useSelector } from 'react-redux';
import loader from '../assets/Icons/loader.gif';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

const GetCallBack = () => {
    const { Request } = UseApi();
    const [loading, setLoading] = useState(false);
    const { userData, login_status } = useSelector(state => state.User);
    const [formData, setFormData] = useState({ name: '', email: '', mobile: '', description: '', countryCode: '+91', terms: false, submited: false });
    const [errorStatus, setErrorStatus] = useState({ name: '', email: '', mobile: '', description: '', countryCode: '', terms: '' });

    const getCallBack = async () => {
        if (checkInputValidation()) return;
        setLoading(true);
        let token = localStorage.getItem('token');
        let inputdata = {
            type: '4',
            token: login_status ? token : '',
            username: formData.name,
            useremail: formData.email,
            country_code: formData.countryCode,
            contact_number: formData.mobile,
            message: formData.description,
            property_type: 'Property',
            action: 'contact_request'

        }
        let data;
        try {
            data = await Request('contact-request', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }
        if (data?.success) {
            toast(data.message, { type: 'success' });
            setFormData(pre => ({ ...pre, submited: true }));
        }
        setLoading(false);
    }

    const checkInputValidation = () => {
        let error = false;
        if (!/^[a-zA-Z\s'-]+$/.test(formData.name)) {
            setErrorStatus(pre => ({ ...pre, name: 'Please write a valid name !' }));
            error = true;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            setErrorStatus(pre => ({ ...pre, email: 'Please write a valid email !' }));
            error = true;
        }
        if (!formData.countryCode) {
            setErrorStatus(pre => ({ ...pre, countryCode: 'Please select a country !' }));
            error = true;
        } else if (!/^\d{7,15}$/.test(formData.mobile)) {
            setErrorStatus(pre => ({ ...pre, mobile: 'Please write a valid contact number!' }));
            error = true;
        }

        if (!formData.description) {
            setErrorStatus(pre => ({ ...pre, description: 'Please write description' }));
            error = true;
        }
        if (!formData.terms) {
            setErrorStatus(pre => ({ ...pre, terms: 'You must accept the term!' }));
            error = true;
        }
        return error;
    }

    return (
        <div className={(!formData.submited ? 'shadow-xl border-[1px]' : '') + ' order-first md:order-none rounded-sm bg-white pb-8 px-4 py-1'}>
            {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                <img alt="Please wait.." title="Please wait.." src={loader} />
            </div>}
            <div className={formData.submited ? 'shadow-xl min-h-[170px] flex flex-col items-center justify-center bg-white border-[1px] ' : '' + ' text-center py-2'}>
                <p className='text-gray-400'>We will take care of</p>
                <p className={styles.title1 + 'text-orange-600'}>SEARCH</p>
            </div>
            {!formData.submited && <div className='mt-5'>
                <input placeholder='Enter your name' className={styles.input + 'py-[5px]  border-gray-300 pl-4'}
                    value={formData.name}
                    onChange={(e) => {
                        setFormData(pre => ({ ...pre, name: e.target.value }));
                        if (errorStatus.name && /^[a-zA-Z\s'-]+$/.test(formData.name)) {
                            setErrorStatus(pre => ({ ...pre, name: '' }))
                        }
                    }}
                />
                {errorStatus.name && <p className='text-sm text-red-600'>{errorStatus.name}</p>}
                <input placeholder='Enter your Email' className={styles.input + 'py-[5px]  border-gray-300 pl-4 mt-3'}
                    value={formData.email}
                    onChange={(e) => {
                        setFormData(pre => ({ ...pre, email: e.target.value }));
                        if (errorStatus.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
                            setErrorStatus(pre => ({ ...pre, email: '' }))
                        }
                    }}
                />
                {errorStatus.email && <p className='text-sm text-red-600'>{errorStatus.email}</p>}
                <div className='flex justify-between mt-4'>
                    <div className='min-w-[105px] w-[34%]'>
                        <PhoneInput
                            country={'in'}
                            // containerStyle={{ width: '30%' }}
                            inputStyle={{ width: '100%', height: '38px', borderRadius: '0px' }}
                            // buttonStyle={{width:'50%'}}
                            // containerClass='w-full'
                            // inputClass='py-2'
                            placeholder='Phone*'
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true,
                                readOnly: true
                            }}
                            // buttonClass='w-[100px]'
                            value={formData.countryCode}
                            // onClick={() => {
                            //     if (formData.countryCode == '') {
                            //         // setValue('+91')
                            //         setFormData(pre => ({ ...pre, countryCode: '+91' }));
                            //     }
                            // }}
                            onChange={(val) => {
                                setFormData(pre => ({ ...pre, countryCode: val }));
                                if (errorStatus.countryCode) {
                                    setErrorStatus(pre => ({ ...pre, countryCode: '' }))
                                }
                            }}
                        />
                    </div>
                    <div className=' w-[65%]'>
                        <input placeholder='Contact Number' type='tel' className={styles.input + 'py-[6px] mt-0 border-gray-300 pl-[15px]'}
                            value={formData.mobile}
                            onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                    setFormData(pre => ({ ...pre, mobile: e.target.value }));
                                    if (errorStatus.mobile && /^\d{7,15}$/.test(e.target.value)) {
                                        setErrorStatus(pre => ({ ...pre, mobile: '' }))
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                {errorStatus.countryCode && <p className='text-sm text-red-600'>{errorStatus.countryCode}</p>}
                {errorStatus.mobile && <p className='text-sm text-red-600'>{errorStatus.mobile}</p>}
                <textarea placeholder='Description' className='w-full h-20 mt-5 px-3 py-2 border border-gray-300 focus:outline-none focus:border-gray-500'
                    value={formData.description}
                    onChange={(e) => {
                        setFormData(pre => ({ ...pre, description: e.target.value }));
                        if (errorStatus.description) {
                            setErrorStatus(pre => ({ ...pre, description: '' }))
                        }
                    }}
                >
                </textarea>
                {errorStatus.description && <p className='text-sm text-red-600'>{errorStatus.description}</p>}
                <label className='flex gap-2 mt-3'>
                    <input type='checkbox' className=''
                        onClick={() => {
                            setFormData(pre => ({ ...pre, terms: !formData.terms }));
                            if (errorStatus.terms && !formData.terms) {
                                setErrorStatus(pre => ({ ...pre, terms: '' }))
                            }
                        }}
                    />
                    <p className='text-gray-500 text-sm'>I agree to Truehomes24.com <NavLink to={'/terms-conditions'} className='underline' >Terms of use</NavLink></p>
                </label>
                {errorStatus.terms && <p className='text-sm text-red-600'>{errorStatus.terms}</p>}
                <button className={styles.btn + 'hover:bg-orange-700 bg-orange-600 py-[6px] opacity-90 w-full text-white mt-5'}
                    onClick={getCallBack}
                >
                    GET CALL BACK
                </button>
            </div>}
        </div>
    );
}

export default GetCallBack;
