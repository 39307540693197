import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer';
import TopCItiesFilter from '../components/TopCItiesFilter';
import BgImage from '../assets/images/buildersBg.jpg'
import { styles } from '../Styles/Styles';

const ConnnectNow = () => {
    return (
        <div>
            <Header />
            <div>
                <div className=' fixed left-0 top-0 h-screen w-screen'>
                    <img alt='' src={BgImage} className='h-full' />
                </div>
                <div className='fixed left-0 h-full mb-2 w-full bg-black bg-opacity-50 overflow-y-scroll'>
                    <div>
                        <div className='relative mt-[100px] h-[100px] z-[200] text-white tracking-wide sm:px-5 lg:px-[5%]'>
                            {/* <p className={'text-center text-2xl sm:text-3xl text-white font-semibold tracking-wider md:text-3xl'}>FAQ</p> */}
                            <div className='text-lg font-semibold'>
                                <p className=''>Welcome Sandip</p>
                                <p className=''>Connect with <span className='text-yellow-300'>2 Buyers</span>, for 2.5 BHK Apartment for Sale in Merlin Waterfront, Kantalia, Howrah</p>
                            </div>
                            <div className='absolute -top-5 right-10 bg-white p-4 rounded-md border-[1px] border-gray-300 text-black text-sm'>
                              <i class="fas fa-credit-card mr-2"></i>  Credit Balance: <span className='text-green-600 font-semibold'>0 Credit</span>
                                <div className='mt-2'>
                                    Use Credit to Send <span className='font-semibold text-gray-600'>Interest to Buyers.</span>
                                </div>
                            </div>
                        </div>
                        <div className='mt-10 pt-10 min-h-[500px] bg-white'>
                            <div className='px-2 mb-12 container mx-auto sm:px-[5%]'>
                                <div className='flex gap-5'>
                                    <div>
                                        <p className='font-semibold'>Buyers Activity</p>
                                        <div className='mt-2 p-2 h-16 w-[240px] rounded-md border-[1px] border-gray-300 shadow'>
                                            <div className='flex gap-5'>
                                                <div className='p-1 h-5 w-5 mt-1 flex items-center justify-center bg-orange-600 rounded-full'>
                                                    <i class="fa-solid fa-check text-white "></i>
                                                </div>
                                                <p className='text-lg font-semibold'>2</p>
                                            </div>
                                            <p className='ml-10'>Buyers in your locality</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className='font-semibold'>Your Activity</p>
                                        <div className='mt-2 p-2 h-16 w-[240px] rounded-md border-[1px] border-gray-300 shadow'>
                                            <div className='flex gap-5'>
                                                <div className='p-1 h-5 w-5 mt-1 flex items-center justify-center bg-gray-600 rounded-full'>
                                                    <i class="fa-solid fa-check text-white "></i>
                                                </div>
                                                <p className='text-lg font-semibold'>0</p>
                                            </div>
                                            <p className='ml-10'>Interest sent so far</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-12'>
                                    <p className={styles.title4}>View Buyers Profile | Connect Instantly | Close Your Deal</p>
                                    <div className='mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-5 lg:gap-10'>
                                        <div className='rounded-md border-[1px] border-gray-300 shadow'>
                                            <div className='p-2 border-b-[1px] border-gray-300 font-semibold'>Sandip</div>
                                            <div className='p-2 px-4 grid grid-cols-2'>
                                                <div>Budget:</div>
                                                <div className='text-left'>30 L - 2 Cr</div>
                                            </div>
                                            <div className='p-2 px-4 grid grid-cols-2'>
                                                <div>Searching Since:</div>
                                                <div className='text-left'>11 days</div>
                                            </div>
                                            <div className='p-2 px-4 grid grid-cols-2 mb-2'>
                                                <div>Last Active:</div>
                                                <div className='text-left'>April 20, 2024</div>
                                            </div>
                                            <span className='p-2 px-4 underline cursor-pointer'>View More Details</span>
                                            <div className='mt-5 p-2 bg-gray-100 flex justify-between'>
                                                <button className={styles.formBtn + styles.btnBlackHover}>Send Interest</button>
                                                <p className='underline cursor-pointer mt-1'>View Phone Number</p>
                                            </div>
                                        </div>

                                        <div className='rounded-md border-[1px] border-gray-300 shadow'>
                                            <div className='p-2 border-b-[1px] border-gray-300 font-semibold'>Sandip</div>
                                            <div className='p-2 px-4 grid grid-cols-2'>
                                                <div>Budget:</div>
                                                <div className='text-left'>30 L - 2 Cr</div>
                                            </div>
                                            <div className='p-2 px-4 grid grid-cols-2'>
                                                <div>Searching Since:</div>
                                                <div className='text-left'>11 days</div>
                                            </div>
                                            <div className='p-2 px-4 mb-2 grid grid-cols-2'>
                                                <div>Last Active:</div>
                                                <div className='text-left'>April 20, 2024</div>
                                            </div>
                                            <span className='p-2 px-4 underline cursor-pointer'>View More Details</span>
                                            <div className='p-2 mt-5 bg-gray-100 flex justify-between'>
                                                <button className={styles.formBtn + styles.btnBlackHover}>Send Interest</button>
                                                <p className='underline cursor-pointer mt-1'>View Phone Number</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TopCItiesFilter />
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConnnectNow;
