import React, { useEffect, useState } from 'react';
import { styles } from '../../Styles/Styles';
import { AreaInputs, ButtonList, CategoryTitle, DropdownInput, InputList } from './PostPropertyComp';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setPostPropertyFormData } from '../../Redux/reducer/User';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UseApi } from '../../ApiConf';
// import loader from '../../assets/Icons/loader.gif';

const listedFor = [
    { label: 'Sale', value: 'Sale' },
    { label: 'Rent', value: 'Rent' },
]

const bedrooms = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '1.5', value: '1.5' },
    { label: '2', value: '2' },
    { label: '2.5', value: '2.5' },
    { label: '3', value: '3' },
    { label: '3.5', value: '3.5' },
    { label: '4', value: '4' },
    { label: '4.5', value: '4.5' },
    { label: '5', value: '5' },
    { label: '5.5', value: '5.5' },
    { label: '6', value: '6' },
    { label: '6.5', value: '6.5' },
    { label: '7', value: '7' },
    { label: '7.5', value: '7.5' },
    { label: '8', value: '8' },
    { label: '8.5', value: '8.5' },
    { label: '9', value: '9' },
    { label: '9.5', value: '9.5' },
    { label: '10+', value: '10+' },
    // { label: '2', value: '2' },
];
const balconies = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10+', value: '10+' }
];
const bathrooms = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5+', value: '5+' }
];


const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const months = nums.map((item, index) => ({ label: `${index} Month`, value: index }));
const years = nums.map((item, index) => ({ label: `${index} Year`, value: index }));

const PropertyInfo = ({ setCurrCategory }) => {
    const [animation, setAnimation] = useState(false);
    // const [loading, setLoading] = useState(true);
    const { postPropertyFormData, login_status, userData, loading } = useSelector(state => state.User);
    const [inputErrorState, setInputErrState] = useState({ country: '', state: '' });
    const [inputFields, setInputFields] = useState({ countries: [], furnishingItem: [], length: [], propertyType: [], status: [], unit: [], ageProperty: [] });
    const [addressData, setAddressData] = useState({ countries: [], states: [], cities: [], localities: [], subLocalities: [], projects: [] });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Request } = UseApi();

    useEffect(() => {
        setAnimation(true);
        getInputOptions('1');
    }, [])


    const getInputOptions = async (type, get_list, currnet_val, locType) => {
        // setLoading(true);
        dispatch(setLoading(true));
        if (!currnet_val && type == '2') return;
        // if (checkInputValidation()) return;
        let params = {
            type: type,
            token: userData?.jwt_tocken,
            get_list: get_list ? get_list : '',
            currnet_val: (currnet_val || currnet_val != 'Other') ? currnet_val : ''
        }
        let data;
        try {
            data = await Request('form-related', 'POST', params);
        } catch (err) {
            console.log(err);
        }
        console.log('data....', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                if (type == '1') {
                    // setPropertyTypes(data.data?.propertyType);
                    // setAddressData(pre => ({ ...pre, countries: data.data?.countries }));
                    setInputFields(pre => ({ ...pre, ...data.data }));
                    if (postPropertyFormData.propertyInfo.country) {
                        getInputOptions('2', 'state_list', postPropertyFormData.propertyInfo.country, 'oldLocation');
                    }
                }
                if (type == '2') {
                    if (get_list == 'state_list') {
                        setAddressData(pre => ({ ...pre, states: data.data, cities: [], localities: [], subLocalities: [], projects: [] }));
                        if (locType == 'oldLocation') {
                            getInputOptions('2', 'city_list', postPropertyFormData.propertyInfo.state, 'oldLocation');
                        }
                    }
                    if (get_list == 'city_list') {
                        setAddressData(pre => ({ ...pre, cities: data.data, localities: [], subLocalities: [], projects: [] }));
                        if (locType == 'oldLocation') {
                            getInputOptions('2', 'locality_list', postPropertyFormData.propertyInfo.city, 'oldLocation');
                            if (postPropertyFormData.propertyInfo.city != 'Other') {
                                getInputOptions('2', 'project_society', postPropertyFormData.propertyInfo.city, 'oldLocation');
                            }
                        } 
                    }
                    if (get_list == 'locality_list') {
                        setAddressData(pre => ({ ...pre, localities: data.data }));
                        if (locType == 'oldLocation') {
                            getInputOptions('2', 'sublocality_list', postPropertyFormData.propertyInfo.locality, 'oldLocation');
                        } 
                    }
                    if (get_list == 'sublocality_list') {
                        setAddressData(pre => ({ ...pre, subLocalities: data.data }));
                    }
                    if (get_list == 'project_society') {
                        setAddressData(pre => ({ ...pre, projects: data.data }));
                    }
                }
                if (data.message) {
                    toast(data.message, { type: 'success' });
                }
            } else {
                // toast('Something went wrong !', { type: 'error' });
            }
            dispatch(setLoading(false));
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            let newFile = e.target.files[0];
            let reader = new FileReader();
            reader.onload = () => {
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, mainImage: reader.result, mainImageFile: newFile } }));
            }
            reader.readAsDataURL(newFile);
        }
    }

    const onClickSave = async () => {
        // if (checkInputValidation()) return;
        // setLoading(true);
        let isLandFlag = (postPropertyFormData.propertyInfo.propertyType == 3 || postPropertyFormData.propertyInfo.propertyType == 9 ||
            postPropertyFormData.propertyInfo.propertyType == 14 || postPropertyFormData.propertyInfo.propertyType == 15 || postPropertyFormData.propertyInfo.propertyType == 16) ? 'Yes' : 'No'
        let inputdata = {
            type: '1',
            token: userData?.jwt_tocken,
            property_id: postPropertyFormData.currPropId,
            is_feartured: postPropertyFormData.type == 'featured' ? 'Yes' : 'No',
            Featured: postPropertyFormData.type == 'featured' ? 'Y' : 'N',
            listedfor: postPropertyFormData.propertyInfo.listedFor,
            property_subtype: postPropertyFormData.propertyInfo.propertyType,
            is_land_flag: isLandFlag,
            country_name: postPropertyFormData.propertyInfo.country,
            state_name: postPropertyFormData.propertyInfo.state,
            location_city: postPropertyFormData.propertyInfo.city,
            locality: postPropertyFormData.propertyInfo.locality,
            landmark: postPropertyFormData.propertyInfo.subLocality,
            property_name: postPropertyFormData.propertyInfo.projectName,
            locality_manual: postPropertyFormData.propertyInfo.otherLocality,
            sublocality_manual: postPropertyFormData.propertyInfo.otherSubLocality,
            ProjectName_manual: postPropertyFormData.propertyInfo.otherProject,
            Bedrooms: postPropertyFormData.propertyInfo.bedrooms,
            Balconies: postPropertyFormData.propertyInfo.balcony,
            Bathrooms: postPropertyFormData.propertyInfo.bathrooms,
            PropertyOnFloor: postPropertyFormData.propertyInfo.propertyOnFloor,
            TotalFloor: postPropertyFormData.propertyInfo.noOfFloor,
            UnitId: '1',
            Unit: '1',
            BuiltUpArea: postPropertyFormData.propertyInfo.buildUpArea,
            build_unit_measurment: postPropertyFormData.propertyInfo.buildUpUnit,
            CarpetArea: postPropertyFormData.propertyInfo.carpetArea,
            carpet_unit_measurment: postPropertyFormData.propertyInfo.carpetUnit,
            TotalArea: postPropertyFormData.propertyInfo.totalArea,
            total_unit_measurment: postPropertyFormData.propertyInfo.totalAreaUnit,
            LengthOfPlot: postPropertyFormData.propertyInfo.lengthOfPlot,
            length_unit: postPropertyFormData.propertyInfo.lengthOfPlotUnit,
            BreadthOfPlot: postPropertyFormData.propertyInfo.breadthOfPlot,
            breadth_unit: postPropertyFormData.propertyInfo.breadthOfPlotUnit,
            FloorAllowed: postPropertyFormData.propertyInfo.FloorAllowed,
            land_month_availabel: postPropertyFormData.propertyInfo.landMonth,
            land_year_availabel: postPropertyFormData.propertyInfo.landYear,
            Availability: postPropertyFormData.propertyInfo.propertyStatus,
            month_availabel: postPropertyFormData.propertyInfo.month,
            year_availabel: postPropertyFormData.propertyInfo.year,
            AgeOfProperty: postPropertyFormData.propertyInfo.AgeOfProperty,
            // old_main_image: postPropertyFormData.propertyInfo.mainImage,
            main_image: postPropertyFormData.propertyInfo.mainImageFile,
            Rera_Id: postPropertyFormData.propertyInfo.reraId,
            AvailableFor: postPropertyFormData.propertyInfo.availableFor,
        }
        console.log('inputdata...', inputdata);
        if (checkInputValidation()) return;
        dispatch(setLoading(true));
        let data;
        try {
            data = await Request('submit-property', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            // if (err?.message) {
            //     setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            // }
        }
        if (data?.statusCode == 200) {
            if (data.success) {
                toast(data.message, { type: 'success' });
                navigate(`/dashboard/add-property/${data.property_id}`);
                setCurrCategory('Amenities');
                dispatch(setPostPropertyFormData({
                    ...postPropertyFormData,
                    currPropId: data.property_id,
                    generalInfo: { ...postPropertyFormData.generalInfo, completed: true },
                    propertyInfo: { ...postPropertyFormData.propertyInfo, completed: true }
                }));
                // toast('Property information saved successfully !', { type: 'success' }); 
                dispatch(setLoading(false));
            } else {
                toast(data.message, { type: 'error' });
                dispatch(setLoading(false));
            }
        }
    }

    const checkInputValidation = () => {
        let error = false;
        if (!postPropertyFormData.propertyInfo.country) {
            setInputErrState(pre => ({ ...pre, country: 'Please select a country !' }));
            error = true;
        }
        if (!postPropertyFormData.propertyInfo.state) {
            setInputErrState(pre => ({ ...pre, state: 'Please select a state !' }));
            error = true;
        }
        if (!postPropertyFormData.propertyInfo.city) {
            setInputErrState(pre => ({ ...pre, city: 'Please select a city !' }));
            error = true;
        }
        if (!postPropertyFormData.propertyInfo.locality) {
            setInputErrState(pre => ({ ...pre, locality: 'Please select a locality !' }));
            error = true;
        }
        if (!postPropertyFormData.propertyInfo.projectName) {
            setInputErrState(pre => ({ ...pre, projectName: 'Please select a project !' }));
            error = true;
        }
        if (!postPropertyFormData.propertyInfo.bedrooms) {
            setInputErrState(pre => ({ ...pre, bedrooms: 'Please select bedrooms !' }));
            error = true;
        }
        if (!postPropertyFormData.propertyInfo.bathrooms) {
            setInputErrState(pre => ({ ...pre, bathrooms: 'Please select bathrooms !' }));
            error = true;
        }
        if (!postPropertyFormData.propertyInfo.balcony) {
            setInputErrState(pre => ({ ...pre, balcony: 'Please select balcony !' }));
            error = true;
        }
        // if (!postPropertyFormData.propertyInfo.buildUpArea) {
        //     setInputErrState(pre => ({ ...pre, buildUpArea: 'Please write build up area !' }));
        //     error = true;
        // }
        // if (!postPropertyFormData.propertyInfo.buildUpUnit) {
        //     setInputErrState(pre => ({ ...pre, buildUpUnit: 'Please select build up unit !' }));
        //     error = true;
        // }
        // if (!postPropertyFormData.propertyInfo.totalArea) {
        //     setInputErrState(pre => ({ ...pre, totalArea: 'Please write total area!' }));
        //     error = true;
        // }
        // if (!postPropertyFormData.propertyInfo.totalAreaUnit) {
        //     setInputErrState(pre => ({ ...pre, totalAreaUnit: 'Please select total area unit!' }));
        //     error = true;
        // }
        // console.log('mainimage...',postPropertyFormData.propertyInfo.mainImage)
        if (!postPropertyFormData.propertyInfo.mainImage) {
            setInputErrState(pre => ({ ...pre, mainImage: 'Please select an image !' }));
            error = true;
        }
        return error;
    }

    return (
        <div className={'mt-16 ' + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')}>
            {/* {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                <img alt="Please wait.." title="Please wait.." src={loader} />
            </div>} */}
            {(postPropertyFormData.generalInfo.completed || login_status) ?
                <>
                    <CategoryTitle title={'Property Information :'} icon={'fa-regular fa-building'} />

                    <div className='md:flex gap-[5%]'>
                        <div className='mb-5 flex-none'>
                            <ButtonList title={'Listed For'} btnNames={listedFor} required={true}
                                value={postPropertyFormData.propertyInfo.listedFor}
                                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, listedFor: item } }))}
                            />
                        </div>
                        <ButtonList title={'Property Type'} btnNames={inputFields.propertyType} required={true} value={postPropertyFormData.propertyInfo.propertyType}
                            onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, propertyType: item } }))}
                        />
                    </div>
                    {/* <InputList inputs={inputs} /> */}
                    <div className={'mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'}>
                        <DropdownInput title={'Country'} options={inputFields.countries} placeholder={'Select Country'} required={true}
                            error={inputErrorState.country}
                            value={postPropertyFormData.propertyInfo.country}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({
                                    ...postPropertyFormData, propertyInfo: {
                                        ...postPropertyFormData.propertyInfo, country: e.target.value,
                                        state: '', stateName: '', city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', projectName: '', societyName: '', otherProject: ''
                                    }
                                }));
                                getInputOptions('2', 'state_list', e.target.value);
                                if (inputErrorState.country) { setInputErrState(pre => ({ ...pre, country: '' })) };
                            }}
                        />
                        <DropdownInput title={'State'} options={addressData.states} placeholder={'Select State'} required={true}
                            value={postPropertyFormData.propertyInfo.state}
                            error={inputErrorState.state}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({
                                    ...postPropertyFormData, propertyInfo: {
                                        ...postPropertyFormData.propertyInfo, state: e.target.value,
                                        city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', projectName: '', societyName: '', otherProject: ''
                                    }
                                }));
                                getInputOptions('2', 'city_list', e.target.value);
                                if (inputErrorState.state) { setInputErrState(pre => ({ ...pre, state: '' })) };
                            }}
                        />
                        <DropdownInput title={'City'} options={addressData.cities} placeholder={'Select City'} required={true}
                            error={inputErrorState.city}
                            value={postPropertyFormData.propertyInfo.city}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({
                                    ...postPropertyFormData, propertyInfo: {
                                        ...postPropertyFormData.propertyInfo, city: e.target.value,
                                        locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '',projectName: '', societyName: '', otherProject: ''
                                    }
                                }));
                                getInputOptions('2', 'locality_list', e.target.value);
                                if (e.target.value && e.target.value != 'Other') {
                                    getInputOptions('2', 'project_society', e.target.value);
                                }
                                if (inputErrorState.city) { setInputErrState(pre => ({ ...pre, city: '' })) };
                            }}
                        />
                        <DropdownInput title={'Locality'} options={addressData.localities} placeholder={'Select Locality'} required={true}
                            error={inputErrorState.locality}
                            value={postPropertyFormData.propertyInfo.locality}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, locality: e.target.value,subLocality: '', otherSubLocality: '' } }));
                                getInputOptions('2', 'sublocality_list', e.target.value);
                                if (inputErrorState.locality) { setInputErrState(pre => ({ ...pre, locality: '' })) };
                            }}
                        />
                        <DropdownInput title={'Sub Locality'} options={addressData.subLocalities} placeholder={'Select Sub Locality'} required={false}
                            value={postPropertyFormData.propertyInfo.subLocality}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, subLocality: e.target.value } }));
                                // if (inputErrorState.subLocality) { setInputErrState(pre => ({ ...pre, subLocality: '' })) };
                            }}
                        />
                        <DropdownInput title={'Project/Society'} options={addressData.projects} placeholder={'Select Project/Society'} required={true}
                            error={inputErrorState.projectName}
                            value={postPropertyFormData.propertyInfo.projectName}
                            onChange={(e) => {
                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, projectName: e.target.value } }));
                                if (inputErrorState.projectName) { setInputErrState(pre => ({ ...pre, projectName: '' })) };
                            }}
                        />
                        {postPropertyFormData.propertyInfo.locality == 'Other' &&
                            <DropdownInput title={'Name Of Your Locality'} placeholder={'Enter Name Of Your Locality'} required={false}
                                value={postPropertyFormData.propertyInfo.otherLocality}
                                onChange={(e) => {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, otherLocality: e.target.value } }));
                                    // if (inputErrorState.otherSubLocality) { setInputErrState(pre => ({ ...pre, otherSubLocality: '' })) };
                                }}
                            />
                        }
                        {postPropertyFormData.propertyInfo.subLocality == 'Other' &&
                            <DropdownInput title={'Name Of Your Sub Locality'} placeholder={'Enter Name Of Your Sub Locality'} required={false}
                                value={postPropertyFormData.propertyInfo.otherSubLocality}
                                onChange={(e) => {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, otherSubLocality: e.target.value } }));
                                    // if (inputErrorState.otherSubLocality) { setInputErrState(pre => ({ ...pre, otherSubLocality: '' })) };
                                }}
                            />
                        }
                        {postPropertyFormData.propertyInfo.projectName == 'Other' &&
                            <DropdownInput title={'Name Of Your Project/Society'} placeholder={'Enter Name Of Your Project/Society'} required={false}
                                value={postPropertyFormData.propertyInfo.otherProject}
                                onChange={(e) => {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, otherProject: e.target.value } }));
                                    // if (inputErrorState.otherProject) { setInputErrState(pre => ({ ...pre, otherProject: '' })) };
                                }}
                            />
                        }
                        {
                            (postPropertyFormData.propertyInfo.propertyType == '1' || postPropertyFormData.propertyInfo.propertyType == '2'
                                || postPropertyFormData.propertyInfo.propertyType == '6' || postPropertyFormData.propertyInfo.propertyType == '10'
                            ) &&
                            <>
                                <DropdownInput title={'Bedrooms'} options={bedrooms} placeholder={'Select Bedrooms'} required={true}
                                    error={inputErrorState.bedrooms}
                                    value={postPropertyFormData.propertyInfo.bedrooms}
                                    onChange={(e) => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, bedrooms: e.target.value } }));
                                        if (inputErrorState.bedrooms) { setInputErrState(pre => ({ ...pre, bedrooms: '' })) };
                                    }}
                                />
                                <DropdownInput title={'Balconies'} options={balconies} placeholder={'Select Balconies'} required={true}
                                    error={inputErrorState.balcony}
                                    value={postPropertyFormData.propertyInfo.balcony}
                                    onChange={(e) => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, balcony: e.target.value } }));
                                        if (inputErrorState.balcony) { setInputErrState(pre => ({ ...pre, balcony: '' })) };
                                    }}
                                />
                                <DropdownInput title={'Bathrooms'} options={bathrooms} placeholder={'Select Bathrooms'} required={true}
                                    error={inputErrorState.bathrooms}
                                    value={postPropertyFormData.propertyInfo.bathrooms}
                                    onChange={(e) => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, bathrooms: e.target.value } }));
                                        if (inputErrorState.bathrooms) { setInputErrState(pre => ({ ...pre, bathrooms: '' })) };
                                    }}
                                />
                            </>}

                        <DropdownInput title={'Property On Floor'} placeholder={'Numbers only'} required={false}
                            //    error={inputErrorState.propertyOnFloor}
                            value={postPropertyFormData.propertyInfo.propertyOnFloor}
                            onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, propertyOnFloor: e.target.value } }));
                                    if (inputErrorState.propertyOnFloor) { setInputErrState(pre => ({ ...pre, propertyOnFloor: '' })) };
                                }
                            }}
                        />
                        <DropdownInput title={'No. Of Floor'} placeholder={'Numbers only'} required={false}
                            value={postPropertyFormData.propertyInfo.noOfFloor}
                            onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, noOfFloor: e.target.value } }));
                                    if (inputErrorState.noOfFloor) { setInputErrState(pre => ({ ...pre, noOfFloor: '' })) };
                                }
                            }}
                        />
                    </div>

                    {/* <AreaInputs inputDatas={areasInputs} /> */}
                    {(postPropertyFormData.propertyInfo.propertyType == '1' || postPropertyFormData.propertyInfo.propertyType == '2'
                        || postPropertyFormData.propertyInfo.propertyType == '6' || postPropertyFormData.propertyInfo.propertyType == '10'
                        || postPropertyFormData.propertyInfo.propertyType == '5' || postPropertyFormData.propertyInfo.propertyType == '7'
                    ) ?
                        <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 gap-3'>
                            <div>
                                <div className='flex justify-between mt-1'>
                                    <DropdownInput title={'BuiltUp Area'} placeholder={'Enter BuiltUp Area'} inputClass={'w-[65%]'} required={true}
                                        error={inputErrorState.buildUpArea}
                                        value={postPropertyFormData.propertyInfo.buildUpArea}
                                        onChange={(e) => {
                                            if (/^[0-9]*$/.test(e.target.value)) {
                                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, buildUpArea: e.target.value } }));
                                                if (inputErrorState.buildUpArea) { setInputErrState(pre => ({ ...pre, buildUpArea: '' })) };
                                            }
                                        }}
                                    />
                                    <DropdownInput title={'Units'} placeholder={'Please Select'} options={inputFields.unit} inputClass={'w-[32%]'} required={true}
                                        error={inputErrorState.buildUpUnit}
                                        value={postPropertyFormData.propertyInfo.buildUpUnit}
                                        onChange={(e) => {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, buildUpUnit: e.target.value } }));
                                            if (inputErrorState.buildUpUnit) { setInputErrState(pre => ({ ...pre, buildUpUnit: '' })) };
                                        }}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='flex justify-between mt-1'>
                                    <DropdownInput title={'Carpet Area'} placeholder={'Enter Carpet Area'} inputClass={'w-[65%]'} required={false}
                                        value={postPropertyFormData.propertyInfo.carpetArea}
                                        onChange={(e) => {
                                            if (/^[0-9]*$/.test(e.target.value)) {
                                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, carpetArea: e.target.value } }));
                                                // if (inputErrorState.carpetArea) { setInputErrState(pre => ({ ...pre, carpetArea: '' })) };
                                            }
                                        }}
                                    />
                                    <DropdownInput title={'Units'} placeholder={'Please Select'} options={inputFields.unit} inputClass={'w-[32%]'} required={false}
                                        value={postPropertyFormData.propertyInfo.carpetUnit}
                                        onChange={(e) => {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, carpetUnit: e.target.value } }));
                                            // if (inputErrorState.carpetUnit) { setInputErrState(pre => ({ ...pre, carpetUnit: '' })) };
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'>
                            <div className='flex justify-between mt-1'>
                                <DropdownInput title={'Total Area'} placeholder={'Enter Total Area'} inputClass={'w-[65%]'} required={true}
                                    error={inputErrorState.totalArea}
                                    value={postPropertyFormData.propertyInfo.totalArea}
                                    onChange={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, totalArea: e.target.value } }));
                                            if (inputErrorState.totalArea) { setInputErrState(pre => ({ ...pre, totalArea: '' })) };
                                        }
                                    }}
                                />
                                <DropdownInput title={'Units'} placeholder={'Please Select'} options={inputFields.unit} inputClass={'w-[32%]'} required={true}
                                    error={inputErrorState.totalAreaUnit}
                                    value={postPropertyFormData.propertyInfo.totalAreaUnit}
                                    onChange={(e) => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, totalAreaUnit: e.target.value } }));
                                        if (inputErrorState.totalAreaUnit) { setInputErrState(pre => ({ ...pre, totalAreaUnit: '' })) };
                                    }}
                                />
                            </div>
                            <div className='flex justify-between mt-1'>
                                <DropdownInput title={'Length Of Plot'} placeholder={'Enter Length Of Plot'} inputClass={'w-[65%]'} required={false}
                                    error={inputErrorState.lengthOfPlot}
                                    value={postPropertyFormData.propertyInfo.lengthOfPlot}
                                    onChange={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, lengthOfPlot: e.target.value } }));
                                            if (inputErrorState.lengthOfPlot) { setInputErrState(pre => ({ ...pre, lengthOfPlot: '' })) };
                                        }
                                    }}
                                />
                                <DropdownInput title={'Units'} placeholder={'Please Select'} options={inputFields.length} inputClass={'w-[32%]'} required={false}
                                    value={postPropertyFormData.propertyInfo.lengthOfPlotUnit}
                                    onChange={(e) => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, lengthOfPlotUnit: e.target.value } }));
                                        if (inputErrorState.lengthOfPlotUnit) { setInputErrState(pre => ({ ...pre, lengthOfPlotUnit: '' })) };
                                    }}
                                />
                            </div>
                            <div className='flex justify-between mt-1'>
                                <DropdownInput title={'Breadth Of Plot'} placeholder={'Enter Breadth Of Plot'} inputClass={'w-[65%]'} required={false}
                                    error={inputErrorState.breadthOfPlot}
                                    value={postPropertyFormData.propertyInfo.breadthOfPlot}
                                    onChange={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, breadthOfPlot: e.target.value } }));
                                            if (inputErrorState.breadthOfPlot) { setInputErrState(pre => ({ ...pre, breadthOfPlot: '' })) };
                                        }
                                    }}
                                />
                                <DropdownInput title={'Units'} placeholder={'Please Select'} options={inputFields.length} inputClass={'w-[32%]'} required={false}
                                    value={postPropertyFormData.propertyInfo.breadthOfPlotUnit}
                                    onChange={(e) => {
                                        dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, breadthOfPlotUnit: e.target.value } }));
                                        if (inputErrorState.breadthOfPlotUnit) { setInputErrState(pre => ({ ...pre, breadthOfPlotUnit: '' })) };
                                    }}
                                />
                            </div>
                        </div>
                    }

                    <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2'>
                        {(postPropertyFormData.propertyInfo.propertyType == '9' || postPropertyFormData.propertyInfo.propertyType == '15'
                            || postPropertyFormData.propertyInfo.propertyType == '3' || postPropertyFormData.propertyInfo.propertyType == '14'
                            || postPropertyFormData.propertyInfo.propertyType == '16'
                        ) ? <>
                            <ButtonList title={'Available For'} btnNames={['Resale', 'New']}
                                required={true} classname={' mt-3'}
                                value={postPropertyFormData.propertyInfo.availableFor}
                                onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, availableFor: item } }))}
                            />
                            <DropdownInput placeholder={'Enter Floors Allowed For Construction'} inputClass={'mt-2'}
                                title={'Floors Allowed For Construction'}
                                value={postPropertyFormData.propertyInfo.FloorAllowed}
                                onChange={(e) => {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, FloorAllowed: e.target.value } }));
                                    if (inputErrorState.FloorAllowed) { setInputErrState(pre => ({ ...pre, FloorAllowed: '' })) };
                                }}
                            />
                            <div className=''>
                                <p>Available From</p>
                                <div className='sm:flex gap-[4%] '>
                                    <DropdownInput options={months} placeholder={'Month'} inputClass={'w-[100%] max-w-[200px] mt-2'}
                                        value={postPropertyFormData.propertyInfo.landMonth}
                                        onChange={(e) => {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, landMonth: e.target.value } }));
                                            if (inputErrorState.landMonth) { setInputErrState(pre => ({ ...pre, landMonth: '' })) };
                                        }}
                                    />
                                    <DropdownInput options={years} placeholder={'Year'} inputClass={'w-[100%] max-w-[200px] mt-2'}
                                        value={postPropertyFormData.propertyInfo.landYear}
                                        onChange={(e) => {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, landYear: e.target.value } }));
                                            if (inputErrorState.landYear) { setInputErrState(pre => ({ ...pre, landYear: '' })) };
                                        }}
                                    />
                                </div>
                            </div>

                        </>
                            :
                            <>
                                <ButtonList title={'Status'} btnNames={inputFields.status}
                                    required={true} classname={' mt-3'}
                                    value={postPropertyFormData.propertyInfo.propertyStatus}
                                    onClick={(item) => dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, propertyStatus: item } }))}
                                />
                                {postPropertyFormData.propertyInfo.propertyStatus != 'Ready to Move' ? <div className=''>
                                    <p>Available From</p>
                                    <div className='sm:flex gap-[4%] '>
                                        <DropdownInput options={months} placeholder={'Month'} inputClass={'w-[100%] max-w-[200px] mt-2'}
                                            value={postPropertyFormData.propertyInfo.month}
                                            onChange={(e) => {
                                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, month: e.target.value } }));
                                                if (inputErrorState.month) { setInputErrState(pre => ({ ...pre, month: '' })) };
                                            }}
                                        />
                                        <DropdownInput options={years} placeholder={'Year'} inputClass={'w-[100%] max-w-[200px] mt-2'}
                                            value={postPropertyFormData.propertyInfo.year}
                                            onChange={(e) => {
                                                dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, year: e.target.value } }));
                                                if (inputErrorState.year) { setInputErrState(pre => ({ ...pre, year: '' })) };
                                            }}
                                        />
                                    </div>
                                </div>
                                    :
                                    <DropdownInput title={'Age Of Property'} options={inputFields.ageProperty} placeholder={'Select Age Of Property'} required={true}
                                        error={inputErrorState.AgeOfProperty}
                                        value={postPropertyFormData.propertyInfo.AgeOfProperty}
                                        onChange={(e) => {
                                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, AgeOfProperty: e.target.value } }));
                                            if (inputErrorState.AgeOfProperty) { setInputErrState(pre => ({ ...pre, AgeOfProperty: '' })) };
                                        }}
                                    />
                                }
                            </>
                        }

                    </div>
                    <div className='mt-5 sm:flex sm:gap-5'>
                        <div className='sm:w-[40%]'>
                            <p>Main Image<span className='text-red-500'>*</span></p>
                            <input required type='file' className={styles.input + 'text-gray-500 text-sm mt-2 py-[5px]'} accept="image/*" onChange={handleFileChange} />
                            <p className='text-gray-500 text-sm mt-2'>Allowed File type: jpg | png | jpeg | gif | bmp</p>
                            <p className='text-gray-500 text-sm'>Max Size Limit: 5 MB.</p>
                            {/* {console.log('postPropertyFormData.propertyInfo.mainImageFile...',postPropertyFormData.propertyInfo.mainImage)} */}
                            {postPropertyFormData.propertyInfo.mainImage && <img src={postPropertyFormData.propertyInfo.mainImage} alt="Selected File" className='mt-2 h-28' />}
                            {/* {error && <div className='text-red-600 text-sm py-1'>{error}</div>} */}
                        </div>
                        <div className='sm:w-[55%]'>
                            <p>RERA Id</p>
                            <input required placeholder='RERA Id' className={styles.input + 'mt-2 pl-5'}
                                value={postPropertyFormData.propertyInfo.reraId}
                                onChange={(e) => {
                                    dispatch(setPostPropertyFormData({ ...postPropertyFormData, propertyInfo: { ...postPropertyFormData.propertyInfo, reraId: e.target.value } }));
                                    // if (inputErrorState.reraId) { setInputErrState(pre => ({ ...pre, reraId: '' })) };
                                }}
                            />
                        </div>
                    </div>
                    <div className='mt-5 flex gap-5'>
                        <button className={styles.formBtn}
                            onClick={() => {
                                if (login_status) {
                                    navigate('/dashboard/my-property/active');
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                } else {
                                    setCurrCategory('General Info');
                                }
                            }}>
                            Back
                        </button>
                        <button className={styles.formBtn} disabled={loading} onClick={onClickSave}>
                            {loading ? 'Please Wait...' : 'Save & Next'}
                        </button>
                    </div>
                </>
                :
                <div className={(animation ? 'transition-opacity opacity-100 duration-500' : 'opacity-0') + ' bg-red-600 rounded text-white p-2 font-semibold'}>
                    Please submit general information first
                </div>
            }

        </div>
    );
}

export default PropertyInfo;
