import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { styles } from '../../Styles/Styles';
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';
import Pagenation from '../../components/Pagenation';
import { toast } from 'react-toastify';

const FavouriteProperty = () => {
    const [loading, setLoading] = useState(true);
    const { Request } = UseApi();
    const [favouriteList, setFavouriteList] = useState({ favouriteProps: [], total: '' });
    const [currPage, setCurrPage] = useState(1);

    useEffect(() => {
        getFavouriteList('1');
    }, [currPage]);

    const getFavouriteList = async (type, id) => {
        let token = localStorage.getItem('token');
        setLoading(true);
        let inputdata = {
            token: token,
            page: currPage,
            limit: '10',
            type: type,
            property_id: id ? id : ''
        }
        let data;
        try {

            data = await Request('favourite-property', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        console.log(' data...', data);
        if (data?.success) {
            if (type == '1') {
                setFavouriteList(data?.data);
            } else if (type == '2') {
                toast(data.message, { type: 'success' });
                if(favouriteList.favouriteProps.length == 1 && currPage > 1){
                    setCurrPage(currPage-1);
                }else{
                    getFavouriteList('1');
                }
            }
        } else {
            // toast(data.message, { type: 'error' });
        }
        setLoading(false);
    }
    return (
        <div>
            <Header />
            <div className='my-20 container mx-auto px-2'>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='overflow-x-auto'>
                    <p className={styles.title2}>Favourite Property ({favouriteList?.total})</p>
                    {favouriteList.favouriteProps.length > 0 && <table className='table-auto min-w-full mt-8 text-center'>
                        <tr className='font-semibold'>
                            <td className="border px-4 py-2 ">Property</td>
                            <td className="border px-4  py-2">Added Date</td>
                            <td className="border px-4 py-2">Action</td>
                        </tr>
                        {favouriteList?.favouriteProps.map((item, index) => {
                            return (
                                <tr>
                                    <td className="border px-4 py-4 ">
                                        <div className='flex gap-4'>
                                            <div className='relative'>
                                                <img class="img-thumbnail"
                                                    className="h-auto w-[120px] max-h-[90px]" src={item.image}
                                                    alt="3 BHK Apartment  for Sale  in Vestas Elegance, Madipakkam, Chennai" />
                                                <div className={'absolute top-0 right-0 bg-orange-500 text-white text-sm py-0 px-1 rounded'}>For {item.listedFor}</div>
                                            </div>
                                            <div>
                                                <p className='text-lg'>{item.propertyTitle}</p>
                                                <div className='flex'>
                                                    <i class="fa-solid fa-location-dot mr-1 mt-1"></i>
                                                    <p>{item.address}</p>
                                                </div>
                                                {item.expectedPrice && item.expectedPrice != '0' && <div className='flex mt-1'>
                                                    {item.listedFor != 'Rent' ? <><p className='text-sm text-left'>start from</p> <p className='font-semibold text-lg ml-5'> / {item.expectedPriceWithUnit}</p> </>
                                                        :
                                                        <p className='font-semibold ml-5'> {item.expectedPriceWithUnit} / Month</p>
                                                    }
                                                </div>}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="border px-4 py-4">{item.date}</td>
                                    <td className="border px-4 py-4">
                                        <button className={styles.formBtn + styles.btnBlackHover} onClick={() => getFavouriteList('2', item.propertyID)}>Remove</button>
                                    </td>
                                </tr>
                            )
                        })}
                        {/* <tr>
                            <td className="border px-4 py-4 ">
                                <div className='flex gap-4'>
                                    <div className='relative'>
                                        <img class="img-thumbnail"
                                            className="h-auto max-h-[80px]" src="https://www.truehomes24.com/assets/properties/banner-02/2c57675b099532defe9e3e73cbc20fd8.webp"
                                            alt="3 BHK Apartment  for Sale  in Vestas Elegance, Madipakkam, Chennai" />
                                        <div className={'absolute top-0 right-0 bg-orange-500 text-white text-sm py-0 px-1 rounded'}>For Sale</div>
                                    </div>
                                    <div>
                                        <p className='text-lg'>3 BHK Apartment For Sale In Vestas Elegance, Madipakkam, Chennai</p>
                                        <div className='flex'>
                                            <i class="fa-solid fa-location-dot mr-1 mt-1"></i>
                                            <p>N/A, Chennai, Tamil Nadu, India</p>
                                        </div>
                                        <div className='flex mt-1'>
                                            <p className='text-sm text-left'>start from</p> <p className='font-semibold ml-5'> / 75 L</p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="border px-4 py-4">April 23, 2024 05:22 PM</td>
                            <td className="border px-4 py-4">
                                <button className={styles.formBtn + styles.btnBlackHover}>Remove</button>
                            </td>
                        </tr> */}
                    </table>}
                </div>
                {favouriteList?.total / 10 > 1 && <div>
                    <Pagenation lastPage={Math.ceil(favouriteList.total / 10)} changeCurrPage={(pageNum) => setCurrPage(pageNum)} />
                </div>}
                {favouriteList.favouriteProps.length == 0 && !loading && <div className='bg-red-600 rounded text-white p-2 font-semibold mt-5'>
                    No Data Found
                </div>}
            </div>
            <TopCItiesFilter />
            <Footer />
        </div>
    );
}

export default FavouriteProperty;
