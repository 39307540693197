import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../../Styles/Styles';
import { setPostPropertyFormData } from '../../Redux/reducer/User';
import { toast } from 'react-toastify';
import { UseApi } from '../../ApiConf';
import { useNavigate } from 'react-router-dom';
import loader from '../../assets/Icons/loader.gif';

const NewProjectGallery = ({ setCurrCategory }) => {
    const [animation, setAnimation] = useState(false);
    // const [files, setFiles] = useState([]);
    // const [selectedFile,setSelectedFile] = useState(null);
    // const [imgSrcs, setImgSrcs] = useState([]);
    const [allFiles, setAllFiles] = useState({ propertyImage: [], floorPlanImages: [], masterPlanImages: [], routeMapImages: [] });
    const [allImgSrc, setAllImgSrc] = useState({ propertyImgScr: [], floorPlanImgScr: [], masterPlanImgScr: [], routeMapImgScr: [] });
    const propImgInp = useRef(null);
    const floorPlanImgInp = useRef(null);
    const masterPlanImgInp = useRef(null);
    const routeMapImgInp = useRef(null);
    // const [checked, setChecked] = useState(false);
    const { postPropertyFormData } = useSelector(state => state.User);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Request } = UseApi();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setAnimation(true);
    }, [])


    // const handleFileChange = (e) => {
    //     // console.log('e.target.files...',e.target.files)
    //     if (e.target.files.length > 0) {
    //         let newFiles = e.target.files;
    //         if ((files.length + newFiles.length) > 20) {
    //             alert('Can not upload more than 20 images !');
    //             return;
    //         }
    //         setFiles([...files, ...newFiles]);
    //         let arr = [...imgSrcs];
    //         for (let i = 0; i < newFiles.length; i++) {
    //             let reader = new FileReader();

    //             // reader.onprogress = (event) => {
    //             //     const progress = Math.round((event.loaded / event.total) * 100);
    //             //     setProgress(progress);
    //             //   };

    //             reader.onload = () => {
    //                 arr = [...arr, reader.result];
    //                 if (i == newFiles.length - 1) {
    //                     setImgSrcs([...arr]);
    //                 }
    //             }
    //             reader.readAsDataURL(newFiles[i]);
    //         }
    //     }
    // }

    // const handleDeleteFile = (index) => {

    //     if (files.length === 1) {
    //         inputRef.current.value = null
    //     }
    //     setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
    //     setImgSrcs([...imgSrcs.slice(0, index), ...imgSrcs.slice(index + 1)]);
    // }

    const handleFileChange = (e, fileType, imgsrcType, maxLength) => {
        // console.log('e.target.files...',e.target.files)
        if (e.target.files.length > 0) {
            let newFiles = e.target.files;
            if ((postPropertyFormData.newProjectGallery[imgsrcType].length + newFiles.length) > maxLength) {
                alert(`Can not upload more than ${maxLength} images !`);
                return;
            }
            setLoading(true);
            setAllFiles(pre => ({ ...pre, [fileType]: [...allFiles[fileType], ...newFiles] }));
            let arr = [...allImgSrc[imgsrcType]];
            for (let i = 0; i < newFiles.length; i++) {
                let reader = new FileReader();

                // reader.onprogress = (event) => {
                //     const progress = Math.round((event.loaded / event.total) * 100);
                //     setProgress(progress);
                //   };

                reader.onload = () => {
                    arr = [...arr, reader.result];
                    console.log('loading....')
                    // if (i == newFiles.length - 1) {
                    //     // setImgSrcs([...arr]);
                    //     setAllImgSrc(pre => ({ ...pre, [imgsrcType]: [...arr] }));
                    // }
                    uploadPhoto(newFiles[i], fileType, i == newFiles.length - 1);
                }
                reader.readAsDataURL(newFiles[i]);
            }
            console.log('loaded....')
        }
    }

    const uploadPhoto = async (file, imageType, isLastImage) => {
        let token = localStorage.getItem('token');
        let inputdata = {
            type: '2',
            token: token,
            imageType: imageType,
            property_id: postPropertyFormData.newProjectInfo.propertyID,
            top_selling_project_id: postPropertyFormData.newProjectInfo.projectId,
            file: file
        }
        console.log('file...', file);
        console.log('inputdata...', inputdata);
        let data;
        try {
            data = await Request('submit-new-project-property', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        if (data?.success) {
            if (isLastImage) {
                toast(data.message, { type: 'success' });
            }
            console.log('data...', data);
        }
        if (isLastImage) {
            getPropertyData();
        }
    }

    const getPropertyData = async () => {
        let token = localStorage.getItem('token');
        let inputdata = {
            top_selling_project_id: postPropertyFormData.newProjectInfo.projectId,
            token: token,
        }
        let data;
        try {
            data = await Request('get-new-project-data', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }
        console.log('result...', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                let gallery = data.data?.gallery;
                dispatch(setPostPropertyFormData({
                    ...postPropertyFormData,
                    newProjectGallery: { completed: false, propertyImgScr: gallery.property ? gallery.property : [], floorPlanImgScr: gallery.floor ? gallery.floor : [], masterPlanImgScr: gallery.masterPlan ? gallery.masterPlan : [], routeMapImgScr: gallery.routeMap ? gallery.routeMap : [] },
                }));
            }
        }
        setLoading(false);
    }

    const handleDeleteFile = async (index, fileType, imgsrcType, inpref, imgId) => {
        setLoading(true);
        let token = localStorage.getItem('token');
        let inputdata = {
            type: '3',
            token: token,
            image_id: imgId,
        }
        console.log('inputdata...', inputdata);
        let data;
        try {
            data = await Request('submit-new-project-property', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        console.log('data...', data);
        if (data?.success) {
            setAllFiles(pre => ({ ...pre, [fileType]: [...allFiles[fileType].slice(0, index), ...allFiles[fileType].slice(index + 1)] }));
            // setAllImgSrc(pre => ({ ...pre, [imgsrcType]: [...allImgSrc[imgsrcType].slice(0, index), ...allImgSrc[imgsrcType].slice(index + 1)] }));
            getPropertyData();
            toast(data.message, { type: 'success' });
        }
        if (allFiles[fileType].length === 1) {
            inpref.current.value = null
        }
        setLoading(false);
    }

    const onClickContinue = () => {
        setCurrCategory('Unit Configuration');
        toast('Gallery Images uploaded successfully !', { type: 'success' });
        dispatch(setPostPropertyFormData({ ...postPropertyFormData, newProjectGallery: { ...postPropertyFormData.newProjectGallery, completed: true } }));
        window.scrollTo({ top: 0 });
    }

    return (
        <div className={'mt-16 ' + (animation ? 'transition-transform ease-in-out transform -translate-y-10 duration-1000' : '')}>
            {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                <img alt="Please wait.." title="Please wait.." src={loader} />
            </div>}
            {postPropertyFormData.newProjectInfo.completed ? <>
                <div>
                    <p className={styles.title2}>Drag and Drop your "Property Images" here(20 Images)</p>
                    <div className='border-dashed border-red-600 border-[1px] mt-5 flex flex-col justify-center p-1'>
                        <input accept='image/*' multiple ref={propImgInp} type='file' draggable
                            className='w-full min-h-[300px] z-50 absolute opacity-0 py-14 cursor-pointer'
                            onChange={(e) => handleFileChange(e, 'propertyImage', 'propertyImgScr', 20)}
                        />
                        <div className='relative text-gray-400 flex flex-col p-2 min-h-[300px] text-cente'>
                            {postPropertyFormData.newProjectGallery.propertyImgScr.length == 0 && <div className='flex flex-col mt-[100px] items-center justify-center'>
                                <i class="fa-solid fa-cloud-arrow-up text-4xl"></i>
                                <p className='text-xl'><span className='font-semibold text-gray-500'>Drop files</span> to upload</p>
                                <p>(or click)</p>
                            </div>}
                            <div className='mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-10 gap-3 sm:gap-5 p-2'>
                                {postPropertyFormData.newProjectGallery.propertyImgScr.map((item, index) => {
                                    return (
                                        <div key={index} className='relative z-50 border-[1px] border-gray-300 p-2'>
                                            <img src={item.Image} alt="Selected File" className='min-h-10 w-full' />
                                            {/* <p className='text-center text-gray-600 text-sm mt-1'>{(files[index].size/1024).toFixed(2)} KB</p> */}
                                            <i onClick={() => handleDeleteFile(index, 'propertyImage', 'propertyImgScr', propImgInp, item.id)}
                                                class="fa-solid fa-circle-xmark  text-sm absolute -top-2 text-red-500 bg-white rounded-full -right-2 cursor-pointer"></i>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-10'>
                    <p className={styles.title2}>Drag and Drop your "Floor Plan Images" here(10 Images)</p>
                    <div className='border-dashed border-red-600 border-[1px] mt-5 flex flex-col justify-center p-1'>
                        <input accept='image/*' multiple ref={floorPlanImgInp} type='file' draggable
                            className='w-full min-h-[300px] z-50 absolute opacity-0 py-14 cursor-pointer'
                            onChange={(e) => handleFileChange(e, 'floorPlanImages', 'floorPlanImgScr', 10)}
                        />
                        <div className='relative text-gray-400 flex flex-col p-2 min-h-[300px] text-cente'>
                            {postPropertyFormData.newProjectGallery.floorPlanImgScr.length == 0 && <div className='flex flex-col items-center justify-center mt-[100px]'>
                                <i class="fa-solid fa-cloud-arrow-up text-4xl"></i>
                                <p className='text-xl'><span className='font-semibold text-gray-500'>Drop files</span> to upload</p>
                                <p>(or click)</p>
                            </div>}
                            <div className='mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-10 gap-3 sm:gap-5 p-2'>
                                {postPropertyFormData.newProjectGallery.floorPlanImgScr.map((item, index) => {
                                    return (
                                        <div key={index} className='relative z-50 border-[1px] border-gray-300 p-2'>
                                            <img src={item.Image} alt="Selected File" className='h-24 w-full' />
                                            {/* <p className='text-center text-gray-600 text-sm mt-1'>{(files[index].size/1024).toFixed(2)} KB</p> */}
                                            <i onClick={() => handleDeleteFile(index, 'floorPlanImages', 'floorPlanImgScr', floorPlanImgInp, item.id)}
                                                class="fa-solid fa-circle-xmark text-sm absolute -top-2 text-red-500 bg-white rounded-full -right-2 cursor-pointer"></i>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-10'>
                    <p className={styles.title2}>Drag and Drop your "Master Plan Images" here(5 Images)</p>
                    <div className='border-dashed border-red-600 border-[1px] mt-5 flex flex-col justify-center p-1'>
                        <input accept='image/*' multiple ref={masterPlanImgInp} type='file' draggable
                            className='w-full min-h-[300px] z-50 absolute opacity-0 py-14 cursor-pointer'
                            onChange={(e) => handleFileChange(e, 'masterPlanImages', 'masterPlanImgScr', 5)}
                        />
                        <div className='relative text-gray-400 flex flex-col p-2 min-h-[300px] text-cente'>
                            {postPropertyFormData.newProjectGallery.masterPlanImgScr.length == 0 && <div className='flex flex-col items-center justify-center mt-[100px]'>
                                <i class="fa-solid fa-cloud-arrow-up text-4xl"></i>
                                <p className='text-xl'><span className='font-semibold text-gray-500'>Drop files</span> to upload</p>
                                <p>(or click)</p>
                            </div>}
                            <div className='mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-10 gap-3 sm:gap-5 p-2'>
                                {postPropertyFormData.newProjectGallery.masterPlanImgScr.map((item, index) => {
                                    return (
                                        <div key={index} className='relative z-50 border-[1px] border-gray-300 p-2'>
                                            <img src={item.Image} alt="Selected File" className='h-24 w-full' />
                                            {/* <p className='text-center text-gray-600 text-sm mt-1'>{(files[index].size/1024).toFixed(2)} KB</p> */}
                                            <i onClick={() => handleDeleteFile(index, 'masterPlanImages', 'masterPlanImgScr', masterPlanImgInp, item.id)}
                                                class="fa-solid fa-circle-xmark text-sm absolute -top-2 text-red-500 bg-white rounded-full -right-2 cursor-pointer"></i>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-10'>
                    <p className={styles.title2}>Drag and Drop your "Route Map Images" here(2 Images)</p>
                    <div className='border-dashed border-red-600 border-[1px] mt-5 flex flex-col justify-center p-1'>
                        <input accept='image/*' multiple ref={routeMapImgInp} type='file' draggable
                            className='w-full min-h-[300px] z-50 absolute opacity-0 py-14 cursor-pointer'
                            onChange={(e) => handleFileChange(e, 'routeMapImages', 'routeMapImgScr', 5)}
                        />
                        <div className='relative text-gray-400 flex flex-col p-2 min-h-[300px] text-cente'>
                            {postPropertyFormData.newProjectGallery.routeMapImgScr.length == 0 && <div className='flex flex-col items-center justify-center mt-[100px]'>
                                <i class="fa-solid fa-cloud-arrow-up text-4xl"></i>
                                <p className='text-xl'><span className='font-semibold text-gray-500'>Drop files</span> to upload</p>
                                <p>(or click)</p>
                            </div>}
                            <div className='mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-10 gap-3 sm:gap-5 p-2'>
                                {postPropertyFormData.newProjectGallery.routeMapImgScr.map((item, index) => {
                                    return (
                                        <div key={index} className='relative z-50 border-[1px] border-gray-300 p-2'>
                                            <img src={item.Image} alt="Selected File" className='h-24 w-full' />
                                            {/* <p className='text-center text-gray-600 text-sm mt-1'>{(files[index].size/1024).toFixed(2)} KB</p> */}
                                            <i onClick={() => handleDeleteFile(index, 'routeMapImages', 'routeMapImgScr', routeMapImgInp, item.id)}
                                                class="fa-solid fa-circle-xmark text-sm absolute -top-2 text-red-500 bg-white rounded-full -right-2 cursor-pointer"></i>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-7 flex gap-5'>
                    <button className={styles.formBtn}
                        onClick={() => {
                            setCurrCategory('New Project Info');
                            window.scrollTo({ top: 0 });
                        }}
                    >Back</button>
                    <button type='submit' className={styles.formBtn} onClick={onClickContinue}>Continue</button>
                </div>
            </>
                :
                <div className={(animation ? 'transition-opacity opacity-100 duration-500' : 'opacity-0') + ' bg-red-600 rounded text-white p-2 font-semibold'}>
                    Please submit new project info first
                </div>
            }
        </div>
    );
}

export default NewProjectGallery;
