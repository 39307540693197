import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import BgImage from '../../assets/images/buildersBg.jpg'
import { NavLink } from 'react-router-dom';
import { styles } from '../../Styles/Styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { UseApi } from '../../ApiConf';
import { toast } from 'react-toastify';
import MetaTags from '../../components/MetaTags';

const ContactUs = () => {
  const [loading, setLoading] = useState(true);
  const [contactusData, setContactUsData] = useState(null);
  const [meta,setMeta] = useState(null);
  const [inputState, setInputState] = useState({ name: '', email: '', countryCode: '+91', phone: '', subject: '', message: '' });
  const [inputErrState, setInputErrState] = useState({ name: '', email: '', countryCode: '', phone: '', subject: '', message: '', });
  const { FetchData, Request } = UseApi();

  useEffect(() => {
    getContactusData();
  }, []);

  const getContactusData = async () => {
    setLoading(true);
    let data;
    try {
      data = await FetchData(`statics?type=1`, 'GET');
    } catch (err) {
      console.log(err);
    }
    if (data) {
      console.log('contactus data..', data);
      setContactUsData(data.data);
      setMeta(data.meta);
    }
    setLoading(false);
  }

  const writeToUs = async () => {
    if (checkInputValidation()) return;
    let inputdata = {
      form_name: inputState.name,
      form_email: inputState.email,
      country_code: inputState.countryCode,
      form_phone: inputState.phone,
      form_subject: inputState.subject,
      form_message: inputState.message,
      page_type: 'contact'
    }
    let data;
    try {
      data = await Request('contact', 'POST', inputdata);
    } catch (err) {
      console.log(err);
    }
    if (data.success) {
      toast(data.message, { type: 'success' });
      setInputState({ name: '', email: '', countryCode: '+91', phone: '', subject: '', message: '' });
    }
  }
  const checkInputValidation = () => {
    let error = false;
    if (!inputState.name) {
      setInputErrState(pre => ({ ...pre, name: 'Please write your name !' }));
      error = true;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputState.email)) {
      setInputErrState(pre => ({ ...pre, email: 'Please write a valid email !' }));
      error = true;
    }
    if (!/^\d{7,15}$/.test(inputState.phone)) {
      setInputErrState(pre => ({ ...pre, phone: 'Please write a valid contact number !' }));
      error = true;
    }
    if (!inputState.subject) {
      setInputErrState(pre => ({ ...pre, subject: 'Please write your subject !' }));
      error = true;
    }
    if (!inputState.message) {
      setInputErrState(pre => ({ ...pre, message: 'Please write your message!' }));
      error = true;
    }
    return error;
  }

  return (
    <div className='container mx-auto'>
      <Header />
      {meta && <MetaTags metaData={meta}/>}
      <div>
        <div className=' fixed left-0 top-0 h-screen w-screen'>
          <img alt='' src={BgImage} className='h-full' />
        </div>
        <div className='fixed left-0 h-full mb-2 w-full bg-black bg-opacity-50 overflow-y-scroll'>
          <div>
            <div className='mt-[150px] h-[100px] z-[200] text-center text-white tracking-widest'>
              <p className={'text-center text-2xl sm:text-3xl text-white font-semibold tracking-wider md:text-3xl'}>Contact Us</p>
              <div className='mt-5 text-xs lg:text-sm'>
                <NavLink to={'/'}>HOME</NavLink> / <span>CONTACT US</span>
              </div>
            </div>
            <div className='mt-10 pt-10 min-h-[500px] bg-white'>
              <div className='px-2 sm:px-10 mb-10 grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                <div className='text-center tracking-wider flex flex-col justify-center items-center'>
                  <i class="fa-solid fa-location-dot text-2xl"></i>
                  <p className={styles.title3}>Corporate office</p>
                  {/* <p className='mt-5'>B14-ANKUR PALM SPRINGS, PADI,</p>
                  <p>CHENNAI-600050</p>
                  <div className='mt-5'>
                    <p className='mt-2'>RERA :TN/Agent/0179/2022(Tamil Nadu)</p>
                    <p className='mt-2'>RERA: K-RERA/AG/0127/2023(Kerala)</p>
                    <p className='mt-2'>RERA: REA30500072290(Telangana)</p>
                    <p className='mt-5'>GST-29AAOCM0530A1ZT</p>
                  </div> */}
                  {/* <div className=''> */}
                  <div className='prose w-[80%] mt-5' dangerouslySetInnerHTML={{ __html: contactusData?.fullAddress }}></div>
                  {/* </div> */}
                </div>
                <div className='text-center tracking-wider'>
                  <i class="fa-solid fa-phone text-xl"></i>
                  <p className={styles.title3}>Direct Contact</p>
                  <a className='mt-5 flex justify-center hover:opacity-60 cursor-pointer' href='tel:918825553678' ><p className='font-semibold'>Phone :</p><p>{contactusData?.directContact}</p></a>
                </div>
                <div className='text-center tracking-wider'>
                  <i class="fa-solid fa-envelope text-xl"></i>
                  <p className={styles.title3}>Email Address</p>
                  <a className='mt-5 hover:opacity-60 cursor-pointer' href='mailto:info@truehomes24.com'>{contactusData?.email}</a>
                </div>
              </div>
              <div className='px-2 sm:px-10 grid grid-cols-1 md:grid-cols-2 gap-5 mb-10'>
                <div>
                  <iframe
                    className='h-[480px] w-full mb-5'
                    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1149598.5863159136!2d79.53335823227894!3d13.048697048542913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265ea4f7d3361:0x6e61a70b6863d433!2sChennai, Tamil Nadu!5e0!3m2!1sen!2sin!4v1604034092548!5m2!1sen!2sin" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    src={contactusData?.mapAddress} frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
                <div>
                  <p className={styles.title1}>Write to us</p>
                  <input required className={styles.input + 'mt-8'} placeholder='Name*'
                    value={inputState.name}
                    onChange={e => {
                      setInputState(pre => ({ ...pre, name: e.target.value }));
                      if (inputErrState.name) {
                        setInputErrState(pre => ({ ...pre, name: '' }));
                      }
                    }}
                  />
                  {inputErrState.name && <p className='text-sm text-red-600'>{inputErrState.name}</p>}
                  <input required className={styles.input + 'mt-5'} placeholder='Email*'
                    value={inputState.email}
                    onChange={e => {
                      setInputState(pre => ({ ...pre, email: e.target.value }));
                      if (inputErrState.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
                        setInputErrState(pre => ({ ...pre, email: '' }));
                      }
                    }}
                  />
                  {inputErrState.email && <p className='text-sm text-red-600'>{inputErrState.email}</p>}
                  <div className='flex mt-5 gap-2'>
                    <div className='w-[30%]'>
                      <PhoneInput
                        country={'in'}
                        // containerStyle={{width:'100%'}}
                        inputStyle={{ width: '100%', height: '42px', borderRadius: '0px' }}
                        // buttonStyle={{width:'50%'}}
                        // containerClass='w-full'
                        // inputClass='py-2'
                        placeholder='Phone*'
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true
                        }}
                        // buttonClass='w-[100px]'
                        value={inputState.countryCode}
                        onClick={() => {
                          if (inputState.countryCode == '') {
                            setInputState(pre => ({ ...pre, countryCode: '+91' }));
                          }
                        }}
                        onChange={val => setInputState(pre => ({ ...pre, countryCode: val }))}
                      />
                    </div>
                    <div className='w-[70%]'>
                      <input required className={styles.input + ''} placeholder='Phone*'
                        value={inputState.phone}
                        onChange={e => {
                          if (/^[0-9]*$/.test(e.target.value)) {
                            setInputState(pre => ({ ...pre, phone: e.target.value }));
                            if (inputErrState.phone && /^\d{7,15}$/.test(e.target.value)) {
                              setInputErrState(pre => ({ ...pre, phone: '' }));
                            }
                          }
                        }}
                      />
                      {inputErrState.phone && <p className='text-sm text-red-600'>{inputErrState.phone}</p>}
                    </div>
                  </div>

                  <input required className={styles.input + 'mt-5'} placeholder='Subject*'
                    value={inputState.subject}
                    onChange={e => {
                      setInputState(pre => ({ ...pre, subject: e.target.value }));
                      if (inputErrState.subject) {
                        setInputErrState(pre => ({ ...pre, subject: '' }));
                      }
                    }}
                  />
                  {inputErrState.subject && <p className='text-sm text-red-600'>{inputErrState.subject}</p>}
                  <textarea required placeholder='Your Message*' className={styles.input + 'h-28 mt-5'}
                    value={inputState.message}
                    onChange={e => {
                      setInputState(pre => ({ ...pre, message: e.target.value }));
                      if (inputErrState.message) {
                        setInputErrState(pre => ({ ...pre, message: '' }));
                      }
                    }}
                  />
                  {inputErrState.message && <p className='text-sm text-red-600'>{inputErrState.message}</p>}
                  <button type='submit'
                    className='px-2 py-1 mt-5 rounded-md bg-orange-600 text-white hover:text-orange-700 hover:bg-white border-[1px] duration-500 border-orange-600'
                    onClick={writeToUs}
                  >Send Message</button>
                </div>
              </div>
              <div className=''>
                <TopCItiesFilter />
                <Footer />
              </div>


            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ContactUs;
