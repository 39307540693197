import { Await, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import PropertyList from './pages/PropertyList';
import Agents from './pages/Agents/Agents';
import Builders from './pages/Builders/Builders';
import ProjectDetails from './pages/ProjectDetails/ProjectDetails';
import PostProperty from './pages/PostProperty/PostProperty';
import NewProject from './pages/PostProperty/NewProject';
import BuyerRegistration from './pages/BuyerRegistration';
import AdPackages from './pages/PostProperty/AdPackages';
import ManageProperty from './pages/MyDashboard/ManageProperty';
import ManageProfile from './pages/MyDashboard/ManageProfile';
import Profile from './pages/Agents/Profile';
import EditProfile from './pages/MyDashboard/EditProfile';
import AdPackageDetail from './pages/PostProperty/AdPackageDetail';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNotFoundPath, setPageRefress, setlocation, setuser } from './Redux/reducer/User';
import ContactUs from './pages/QuickLinks/ContactUs';
import FAQs from './pages/QuickLinks/FAQs';
import Blogs from './pages/QuickLinks/Blogs';
import BlogDetails from './pages/QuickLinks/BlogDetails';
import TermsAndCondition from './pages/QuickLinks/TermsAndCondition';
import AboutUs from './pages/QuickLinks/AboutUs';
import PrivacyPolicy from './pages/QuickLinks/PrivacyPolicy';
import RefundPolicy from './pages/QuickLinks/RefundPolicy';
import Sitemap from './pages/QuickLinks/Sitemap';
import PageNotFound from './pages/PageNotFound';
import ViewResponse from './pages/MyDashboard/ViewResponse';
import { UseApi } from './ApiConf';
import ConnnectNow from './pages/ConnnectNow';
import ContactRequest from './pages/MyDashboard/ContactRequest';
import FavouriteProperty from './pages/MyDashboard/FavouriteProperty';
import MyOrders from './pages/MyDashboard/MyOrders';
// import { Helmet } from 'react-helmet';

function App() {
  const { pageRefresh, currLocation, builderSearchStatus, postPropertyFormData, pageNotFoundPath, login_status } = useSelector(state => state.User);
  const dispatch = useDispatch();
  const location = useLocation();
  const { Request } = UseApi();
  const navigate = useNavigate();

  useEffect(() => {
    // if (token && token != '') {
    //   dispatch(setuser({}));
    // };
    // let token = localStorage.getItem('token');
    verifyToken();
    if (pageRefresh) {
      dispatch(setPageRefress(false));
    }
    getCurrLocation();
  }, []);

  const verifyToken = async () => {
    const token = localStorage.getItem('token');
    let inputdata = {
      token: token
    }
    let data;
    try {
      data = await Request('autologin', 'POST', inputdata);
    } catch (err) {
      console.log('error...', err);
    }
    console.log('auto login data...', data, 'token...', token);
    if (data?.success) {
      localStorage.setItem('token', data?.token);
      if (data.data) {
        dispatch(setuser(data.data));
        localStorage.setItem('userId', data?.data?.ID);
      } else {
        dispatch(setuser({}));
      }
    } else {
      let pathArr = location.pathname.split('/');
      if (pathArr[1] == 'dashboard') {
        navigate('/');
      }
    }
  }

  const getCurrLocation = () => {
    if (currLocation.area != 'City') return;
    let location = localStorage.getItem('location');
    // console.log('locationnn...',location);
    if (location && location != '') {
      dispatch(setlocation(JSON.parse(location)));
    }
  }

  return (
    <>
      <Routes>
        {/* {console.log('first.....',`/${pageNotFoundPath}`,location.pathname)} */}
        <Route path='/' element={<Home />} />
        <Route path='/:detail' element={location.pathname.includes('real-estate-builders') ? <Builders /> : <PropertyList />} />
        <Route path='/sale/:pathCity' element={<PropertyList />} />
        <Route path='/rent/:pathCity' element={<PropertyList />} />
        <Route path='/new-projects/:pathCity' element={<PropertyList />} />
        <Route path='/property/:type' element={<PropertyList />} />
        <Route path='/sale/:path/:id' element={<ProjectDetails />} />
        <Route path='/rent/:path/:id' element={<ProjectDetails />} />
        <Route path='/new-projects/:path/:id' element={<ProjectDetails />} />
        <Route path='/dev1/sale/:detail' element={<PropertyList />} />
        <Route path='/dev1/rent/:detail' element={<PropertyList />} />
        {/* <Route path='/property/residential' element={<PropertyList />} />
        <Route path='/property/commercial-land' element={<PropertyList />} />
        <Route path='/property/plot' element={<PropertyList />} />
        <Route path='/property/commercial' element={<PropertyList />} /> */}
        {/* <Route path='dev1/new-projects/:detail' element={<PropertyList/>} /> */}
        <Route path='/dev1/:detail' element={<PropertyList />} />
        <Route path='/agents/real-estate-agents-in-india' element={<Agents />} />
        <Route path='/agent/:path' element={<Agents />} />
        <Route path='/agents/:path' element={<Agents />} />
        <Route path='/agent-profile' element={<Profile />} />
        <Route path='/user/detail/:id/:name' element={<Profile />} />
        <Route path='/user/detail/:id' element={<Profile />} />
        {/* <Route path='/builders' element={<Builders/>} /> */}
        {/* <Route path='/real-estate-builders-in-india' element={<Builders/>} /> */}
        <Route path={`/${builderSearchStatus.builderPath}real-estate-builders-in-${builderSearchStatus.cityPath}`} element={<Builders />} />
        <Route path='/project_details' element={<ProjectDetails />} />
        <Route path='/post-property' element={<PostProperty />} />
        {/* <Route path={`/dashboard/add-property/${postPropertyFormData.currPropId}`} element={<PostProperty />} /> */}
        <Route path={`/dashboard/add-property/:id`} element={<PostProperty />} />
        <Route path='/post-property/featured' element={<PostProperty />} />
        <Route path='/post-property/new-projects' element={<NewProject />} />
        <Route path='/post-property/new-projects/:id/:tabType' element={<NewProject />} />
        <Route path='/post-property/new-projects/:id' element={<NewProject />} />
        <Route path='/buyer-registration' element={<BuyerRegistration />} />
        <Route path='/membership' element={<AdPackages />} />
        <Route path='/membership/adpackage/:propId/:packageId' element={<AdPackages />} />
        <Route path='/membership/adpackagee/:propId/:packageId' element={<AdPackages />} />
        <Route path='//membership/adpackagee' element={<AdPackages />} />
        <Route path='/membership/adpackage' element={<AdPackages />} />
        <Route path='/membership/detail' element={<AdPackageDetail />} />
        <Route path='/dashboard/my-property/active' element={<ManageProperty />} />
        <Route path='/dashboard/contact-request' element={<ContactRequest />} />
        <Route path='/dashboard/favourite-property' element={<FavouriteProperty />} />
        <Route path='/dashboard/connect-now/:id/:id' element={<ConnnectNow />} />
        <Route path='/dashboard/my-profile' element={<ManageProfile />} />
        <Route path='/dashboard/edit-profile' element={<EditProfile />} />
        <Route path='/dashboard/received-leads' element={<ViewResponse />} />
        <Route path='dashboard/my-order' element={<MyOrders />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/faqs' element={<FAQs />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blog-detail' element={<BlogDetails />} />
        <Route path='/blogs/:detail' element={<BlogDetails />} />
        <Route path='/terms-conditions' element={<TermsAndCondition />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/sitemap' element={<Sitemap />} />
        <Route path='/:notfound/:notfound' element={<PageNotFound />} />
        <Route path='/:notfound/:notfound/:notfound' element={<PageNotFound />} />
        <Route path='/:notfound/:notfound/:notfound/:notfound' element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
