import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { styles } from '../../Styles/Styles';
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';
import Pagenation from '../../components/Pagenation';

const ContactRequest = () => {
    const [loading, setLoading] = useState(true);
    const { Request } = UseApi();
    const [contactReqList, setContactReqList] = useState({ contacts: [], total: '' });
    const [currPage, setCurrPage] = useState(1);

    useEffect(() => {
        getContactReqList();
    }, [currPage]);

    const getContactReqList = async () => {
        let token = localStorage.getItem('token');
        setLoading(true);
        let inputdata = {
            token: token,
            page: currPage,
            limit: '10',
        }
        let data;
        try {
            data = await Request('buyer-contact-request', 'POST', inputdata);
        } catch (err) {
            console.log('error...', err);
        }
        console.log(' data...', data);
        if (data?.success && data.data) {
            setContactReqList(data.data);
        } else {
            // toast(data.message, { type: 'error' });
        }
        setLoading(false);
    }
    return (
        <div>
            <Header />
            <div className='my-20 container mx-auto px-2'>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='overflow-x-auto'>
                    <p className={styles.title2}>My Request ({contactReqList.total})</p>
                    {contactReqList?.total > 0 && <table className='table-auto min-w-full mt-8 text-center'>
                        <tr className='font-semibold'>
                            <td className="border px-4 py-2 ">Property Name</td>
                            <td className="border px-4  py-2">Property Locality</td>
                            <td className="border px-4 py-2">My Message</td>
                            <td className="border px-4 py-2">Date/Time</td>
                        </tr>
                        {contactReqList.contacts?.map((item, index) => {
                            return (
                                <tr>
                                    <td className="border px-4 py-4 underline cursor-pointer">{item.propertyName}</td>
                                    <td className="border px-4 py-4">{item.propertyLocality}</td>
                                    <td className="border px-4 py-4">{item.message}</td>
                                    <td className="border px-4 py-4">{item.date}</td>
                                </tr>
                            )
                        })}
                    </table>}
                </div>
                {contactReqList?.total / 10 > 1 && <div>
                    <Pagenation lastPage={Math.ceil(contactReqList.total / 10)} changeCurrPage={(pageNum) => setCurrPage(pageNum)} />
                </div>}
                {contactReqList.total == 0  && !loading && <div className='bg-red-600 rounded text-white p-2 font-semibold mt-5'>
                    No Data Found
                </div>}
            </div>
            <TopCItiesFilter />
            <Footer />
        </div>
    );
}

export default ContactRequest;
