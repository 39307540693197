import React, { useEffect, useState } from 'react';
import { CrossIcon, MenuIcon, UserIcon } from './svgIcons';
import { styles } from '../Styles/Styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux';
import { UseApi } from '../ApiConf';
import { toast } from 'react-toastify';
import loader from '../assets/Icons/loader.gif';
import { NavLink } from 'react-router-dom';
// import userIcon from '../assets/images/user.svg'


const Contact = ({ Data, func }) => {
    const [currFormState, setCurrFormState] = useState({ showContactForm: true, showGenerateOtp: false, showOtpVerify: false, showResponse: false });
    const { userData, login_status } = useSelector(state => state.User);
    const [formData, setFormData] = useState({ name: '', email: '', mobile: '', description: 'Hello, I am interested.', countryCode: '+91', terms: false, reqId: '', otp: '', seller_contact: '' });
    const [errorStatus, setErrorStatus] = useState({ name: '', email: '', mobile: '', description: '', countryCode: '', terms: '' });
    const { Request } = UseApi();
    const [loading, setLoading] = useState(false);

    // const dispatch = useDispatch();
    useEffect(() => {
        console.log('data contact....', Data);
        console.log('userData...', userData);
        if (userData) {
            let arr = ['+91', ''];
            if (userData?.ContactNumber) {
                arr = userData?.ContactNumber.split('-');
            }
            setFormData(pre => ({ ...pre, name: userData.Name, email: userData.Email, mobile: arr[1], countryCode: arr[0] }));
        }
    }, []);

    const onSubmitContact = async () => {
        if (checkInputValidation()) return;
        setLoading(true);
        let token = localStorage.getItem('token');
        let inputdata = {
            type: '1',
            token: login_status ? token : '',
            username: formData.name,
            useremail: formData.email,
            country_code: formData.countryCode,
            contact_number: formData.mobile,
            message: formData.description,
            property_id: Data.propId,
            property_type: 'Property',
            isNewProject: Data.isNewProject,
            city: Data.city,
            action: 'contact_request'
        }
        let data;
        try {
            data = await Request('contact-request', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            if (err?.message) {
                // setOtpVerificationStatus(pre => ({ ...pre, error: err.message }));
            }
        }
        console.log('contact req data2...', data);
        if (data?.success) {
            toast(data.message, { type: 'success' });
            if (data.is_verified_mobile != 'Y') {
                setCurrFormState(pre => ({ ...pre, showContactForm: false, showGenerateOtp: true }))
                setFormData(pre => ({ ...pre, reqId: data.request_id, mobile: data.mobile }));
            }
        }
        setLoading(false);
    }

    const checkInputValidation = () => {
        let error = false;
        if (!/^[a-zA-Z\s'-]+$/.test(formData.name)) {
            setErrorStatus(pre => ({ ...pre, name: 'Please write a valid name !' }));
            error = true;
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            setErrorStatus(pre => ({ ...pre, email: 'Please write a valid email !' }));
            error = true;
        }
        if (!formData.countryCode) {
            setErrorStatus(pre => ({ ...pre, countryCode: 'Please select a country !' }));
            error = true;
        } else if (!/^\d{7,15}$/.test(formData.mobile)) {
            setErrorStatus(pre => ({ ...pre, mobile: 'Please write a valid contact number!' }));
            error = true;
        }

        if (!formData.description) {
            setErrorStatus(pre => ({ ...pre, description: 'Please write description' }));
            error = true;
        }
        if (!formData.terms) {
            setErrorStatus(pre => ({ ...pre, terms: 'You must accept the term!' }));
            error = true;
        }
        return error;
    }


    // const generateOtp = async () => {
    //     let token = localStorage.getItem('token');
    //     let inputdata = {
    //         type: '2',
    //         token: login_status ? token : '',
    //         request_id: formData.reqId
    //     }
    //     let data;
    //     try {
    //         data = await Request('contact-request', 'POST', inputdata);
    //     } catch (err) {
    //         console.log(err);
    //         if (err?.message) {
    //             // setOtpVerificationStatus(pre => ({ ...pre, error: err.message }));
    //         }
    //     }
    //     if (data.success) {
    //         toast(data.message, { type: 'success' });
    //         setCurrFormState(pre => ({ ...pre, showGenerateOtp: false, showOtpVerify: true }));
    //     } else {
    //         toast(data.message, { type: 'error' });
    //     }
    // }
    const otpVerification = async (type) => {
        setLoading(true);
        let token = localStorage.getItem('token');
        let inputdata = {
            type: type,
            token: login_status ? token : '',
            request_id: formData.reqId,
            otp: type == '3' ? formData.otp : '',
            seller_contact: Data.sellerContact ? Data.sellerContact : ''
        }
        let data;
        try {
            data = await Request('contact-request', 'POST', inputdata);
        } catch (err) {
            console.log(err);
        }
        console.log('send otp data..', data);
        if (data.success) {
            toast(data.message, { type: 'success' });
            if (type == '2') {
                setCurrFormState(pre => ({ ...pre, showGenerateOtp: false, showOtpVerify: true }));
            } else if (type == '3') {
                setCurrFormState(pre => ({ ...pre, showOtpVerify: false, showResponse: true }));
                setFormData(pre => ({ ...pre, seller_contact: data.seller_contact }));
            }
        }
        setLoading(false);
    }

    return (
        <div className='fixed top-0 z-[2000] flex left-0 w-screen h-screen items-center justify-center bg-black bg-opacity-60'>
            <div className='relative bg-white shadow-lg border-[1px] p-4 w-[90%] sm:w-[85%] max-w-[360px]'>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <button
                    onClick={() => func()}
                    className='absolute top-2 right-2'>
                    <CrossIcon />
                </button>
                <div className='flex gap-5'>
                    <img alt=''
                        src={Data.icon}
                        className='h-[50px] w-[50px] md:h-[70px] md:w-[70px]' />
                    <div className='text-gray-900 mt-2'>
                        <div className='flex gap-3'>
                            <UserIcon classname={'h-5 w-5 mt-[2px]'} />
                            {Data.owner}
                        </div>
                        <div className='flex gap-3'>
                            <div className='h-5 w-5 mt-[1px]'>
                                <MenuIcon />
                            </div>
                            {Data.type}
                        </div>
                    </div>
                </div>
                <div className='mt-5'>
                    {currFormState.showContactForm && <div>
                        <input placeholder='Enter your name' className={styles.input + ' py-1 border-gray-300 pl-4'}
                            value={formData.name}
                            onChange={(e) => {
                                setFormData(pre => ({ ...pre, name: e.target.value }));
                                if (errorStatus.name && /^[a-zA-Z\s'-]+$/.test(formData.name)) {
                                    setErrorStatus(pre => ({ ...pre, name: '' }))
                                }
                            }}
                        />
                        {errorStatus.name && <p className='text-sm text-red-600'>{errorStatus.name}</p>}
                        <input placeholder='Enter your Email' className={styles.input + ' py-1 border-gray-300 pl-4 mt-3'}
                            value={formData.email}
                            onChange={(e) => {
                                setFormData(pre => ({ ...pre, email: e.target.value }));
                                if (errorStatus.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
                                    setErrorStatus(pre => ({ ...pre, email: '' }))
                                }
                            }}
                        />
                        {errorStatus.email && <p className='text-sm text-red-600'>{errorStatus.email}</p>}
                        <div className='flex mt-4'>
                            <div className='w-[30%]'>
                                <PhoneInput
                                    country={'in'}
                                    // containerStyle={{ width: '30%' }}
                                    inputStyle={{ width: '100%', height: '42px', borderRadius: '0px' }}
                                    // buttonStyle={{width:'50%'}}
                                    // containerClass='w-full'
                                    // inputClass='py-2'
                                    placeholder='country code*'
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true,
                                        readOnly: true
                                    }}
                                    // buttonClass='w-[100px]'
                                    value={formData.countryCode}
                                    onClick={() => {
                                        if (formData.countryCode == '') {
                                            // setValue('+91')
                                            setFormData(pre => ({ ...pre, countryCode: '+91' }));
                                        }
                                    }}
                                    onChange={(val) => {
                                        setFormData(pre => ({ ...pre, countryCode: val }));
                                        if (errorStatus.countryCode) {
                                            setErrorStatus(pre => ({ ...pre, countryCode: '' }))
                                        }
                                    }}
                                // disabled
                                />
                            </div>
                            <div className='w-[70%]'>
                                <input placeholder='Contact Number' className={styles.input + 'h-[42px] mt-0 border-gray-300 pl-[15px]'}
                                    value={formData.mobile}
                                    onChange={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                            setFormData(pre => ({ ...pre, mobile: e.target.value }));
                                            if (errorStatus.mobile && /^\d{7,15}$/.test(e.target.value)) {
                                                setErrorStatus(pre => ({ ...pre, mobile: '' }))
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {errorStatus.countryCode && <p className='text-sm text-red-600'>{errorStatus.countryCode}</p>}
                        {errorStatus.mobile && <p className='text-sm text-red-600'>{errorStatus.mobile}</p>}
                        <textarea
                            placeholder='Description'
                            className='w-full h-20 mt-5 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-gray-500'
                            value={formData.description}
                            onChange={(e) => {
                                setFormData(pre => ({ ...pre, description: e.target.value }));
                                if (errorStatus.description) {
                                    setErrorStatus(pre => ({ ...pre, description: '' }))
                                }
                            }}
                        >
                        </textarea>
                        {errorStatus.description && <p className='text-sm text-red-600'>{errorStatus.description}</p>}
                        <label className='flex gap-2 mt-3'>
                            {console.log('formData.terms...', formData.terms)}
                            <input type='checkbox' className='' checked={formData.terms} onClick={() => {
                                setFormData(pre => ({ ...pre, terms: !formData.terms }));
                                if (errorStatus.terms && !formData.terms) {
                                    setErrorStatus(pre => ({ ...pre, terms: '' }))
                                }
                            }}
                            />
                            <p className='text-gray-500 text-sm'>I agree to Truehomes24.com <NavLink to={'/terms-conditions'} className='underline' >Terms of use</NavLink></p>
                        </label>
                        {errorStatus.terms && <p className='text-sm text-red-600'>{errorStatus.terms}</p>}
                        <button
                            onClick={onSubmitContact}
                            className={styles.btn + 'bg-black py-[6px] w-full text-white mt-5 '}
                            disabled={loading}
                        // type='submit'
                        >
                            {/* {loading && <svg class="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                            </svg>} */}
                            {/* {loading && <svg
                                className="animate-spin h-5 w-5 text-white mt-1 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.373A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.565zm12 0l3 2.565A7.962 7.962 0 0120 12h-4c0 3.042-1.135 5.824-3 7.938l-3-2.565z"
                                ></path>
                            </svg>} */}
                            {loading ? 'Processing...' : 'Submit'}
                        </button>
                    </div>}
                    {(currFormState.showGenerateOtp || currFormState.showOtpVerify) && <p className={styles.title4}>Verify your mobile number: {formData.mobile} </p>}
                    {currFormState.showGenerateOtp && <div>
                        <button
                            onClick={() => otpVerification('2')}
                            className={styles.btn + 'bg-black py-[6px] w-full text-white mt-5'}>
                            Generate OTP
                        </button>
                    </div>}
                    {currFormState.showOtpVerify && <div className='text-center mt-5'>
                        <input placeholder='Enter OTP received on your mobile' className={styles.input + ' py-1 border-gray-300 pl-4'}
                            value={formData.otp}
                            onChange={(e) => setFormData(pre => ({ ...pre, otp: e.target.value }))}
                        />
                        <button
                            onClick={() => otpVerification('3')}
                            className={styles.btn + 'bg-black py-[6px] w-full text-white mt-5'}>
                            Verify OTP
                        </button>
                        <p className='text-gray-500 mt-5'>You will receive OTP via sms within 1 minute.</p>
                        <p className='text-gray-500 mt-5'>Did not get verification code?</p>
                        <button className='text-gray-700 font-semibold mt-5 underline cursor-pointer' onClick={() => otpVerification('2')} >Resend OTP</button>
                    </div>}
                    {currFormState.showResponse && <div className='mt-5'>
                        <p className={styles.title3 + ' text-center pb-3'}> 3 BHK Apartment for Rent in Teynampet, Chennai</p>
                        <div className='border-t-[1px] border-b-gray-500 p-1 text-center'>
                            <p className={styles.title3}>Thank You...</p>
                        </div>
                        <div className={'flex justify-center mt-2 text-center border-[1px] border-gray-300 py-1'}>
                            Seller Contact: <p className='font-semibold text-sm ml-1 mt-1'>{formData.seller_contact}</p>
                        </div>
                        <div className='mt-5 p-1 border-gray-300 border-[1px]'>
                            <div className='border-b-[1px] border-b-gray-300 pb-1 text-center'>
                                <p className={styles.title4}>Advertise your properties for less then</p><span className='text-orange-600 font-semibold'><i className='fa-solid fa-indian-rupee-sign text-sm'></i> 10/day</span>
                            </div>
                            <div className='mt-2'>
                                <div className='flex mt-2'>
                                    <div className=' flex-none'><i class="fa-solid fa-check bg-gray-100 text-gray-700 p-1 mt-1 rounded-full"></i></div>
                                    <p className='ml-2'>Get certified agents for 3 Months, at Rs. 10000</p>
                                </div>
                                <div className='flex mt-2'>
                                    <div className=' flex-none'><i class="fa-solid fa-check text-gray-700 bg-gray-100 p-1 mt-1 rounded-full"></i></div>
                                    <p className='ml-2'>Visibility above owner listing</p>
                                </div>
                                <div className='flex mt-2'>
                                    <div className=' flex-none'><i class="fa-solid fa-check text-gray-700 bg-gray-100 p-1 mt-1 rounded-full"></i></div>
                                    <p className='ml-2'>Unlimited responses</p>
                                </div>
                            </div>
                        </div>

                    </div>}
                </div>
            </div>
        </div>
    );
}

export default Contact;
