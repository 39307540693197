import { createSlice } from '@reduxjs/toolkit'

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    userData: null,
    // footerData:null,
    currLocation: { country: '90', city: 'India', code: '', location: '', locationName: null, project: '', projectName: null, area: 'City' },
    packageDetails: {propId:'',packageId:'',selectedDays:'',projectId:''},
    propertyListState: {
      propertyStatus: { text: 'Buy', value: 'sale', for: 'Sale', index: 0 },
      BHKtype: '',
      propertyTypes: '',
      priceRange: ['', ''],
      moreStatus: { furnishingTypes: '', bathrooms: '', minArea: '', maxArea: '', newResale: '', constructionStatus: '', facing: '', amenities: '', listedBy: '', floor: '' },
      sortBy: 'featured',
      builder:'',
      clearAll: false
    },
    gettingListPageUrlData:true,
    outSideFilterState: {
      propertyTypes: false
    },
    builderSearchStatus: {
      cityPath: 'india',
      builderPath: '',
      cityName: '',
      builderName: '',
      city: '', name: '', quary: null, showResults: false, showError: false,
      selectedCityOption: JSON.stringify({ cityID: '', cityName: '' })
    },
    currPage: 1,
    pageRefresh: true,
    filterMenus: null,
    propertyStatus: 'sale',
    postPropertyFormData: {
      type: '',
      currPropId: '',
      PlanStatus: '',
      currency: '',
      generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: false },
      propertyInfo: {
        completed: false, listedFor: 'Sale', propertyType: '1', country: '', countryName: '', state: '', stateName: '', city: '', cityName: '', locality: '', localityName: '', otherLocality: '', subLocality: '', otherSubLocality: '', projectName: '', societyName: '', otherProject: '',
        bedrooms: '', bathrooms: '', balcony: '', propertyOnFloor: '', noOfFloor: '', buildUpArea: '', buildUpUnit: '', carpetArea: '', carpetUnit: '', propertyStatus: 'Under Construction',
        availableFor: 'Resale', month: '', year: '', landMonth: '', landYear: '', reraId: '', totalArea: '', totalAreaUnit: '', lengthOfPlot: '', lengthOfPlotUnit: '', breadthOfPlot: '', breadthOfPlotUnit: '', Featured: 'N', is_feartured: 'No',
        AgeOfProperty: '', mainImage: '', mainImageFile: null, FloorAllowed: ''
      },
      amenities: {
        completed: false, salePrice: '', pricePerUnit: '', isPriceNegotiable: 'N', facing: '', ownership: '', parking: '', flooringType: '', view: '', furnishingStatus: '',
        aminities: {}, adPackage: '', aboutProperty: '', aboutBuilder: '', embadedLocation: '', ExpectedPriceRent: '', ElectricityChargeIncluded: 'No', Maintenance: '', SecurityDeposite: '0', MaintenanceType: '',
        DurationOfRentAgreement: '', MonthOfNotice: '', furnishingItems: {}, address: ''
      },
      nearByPlace: {
        completed: false, busStation: '', railwayMetro: '', airport: '', hospital: '', worship: '', atm: '', school: '', mall: '', mainRoad: '', highway: '', expressWay: '',
        bank: '', publicGarden: '', amusementPark: '', milkDiary: '', movieTheatre: '', restaurant: '', library: '', swimmingPool: '', petrolGasPump: '', motorGarage: '', carChargingPoint: '',
        spaBeautySalon: '', pharmacy: ''
      },
      buyRentGallary: { completed: false, imageUrls:[],imgSrcs:[],imageFiles:[], video: '' },
      newProjectGallery: { completed: false,propertyImgScr:[],floorPlanImgScr:[],masterPlanImgScr:[],routeMapImgScr:[]},
      newProjectInfo: {
        completed: false, country: '', state: '', city: '', locality: '', otherLocality: '', subLocality: '', otherSubLocality: '', societyName: '', otherSociety: '', propertyType: '', bedroomFrom: '', bedRoomTo: '',
        bathroomsFrom: '', bathroomsTo: '', totalFloor: '', amenities: {}, furnishingType: '', furnishingItem: [], areaFrom: '', areaUnit: '', areaTo: '', areaToUnit: '',
        priceFrom: '', priceTo: '', builderName: '', projectStaus: '', description: '', aboutBuilder: '', projectLink: '', image: '',imageUrl:'', imageFile: null, video: '',videoUrl:'', possession: '', projectId: '', propertyID: ''
      },
      unitConfig: {
        completed: false, unitFrom: '', unitTo: '', unitType: '', availability: '', builtUpAreaFrom: '', builtUpAreaTo: '', builtUpUnit: '', carpetAreaFrom: '', carpetAreaTo: '', carpetUnit: '',
        minPrice: '', maxPrice: '', liveInTourVideo: null, makePayment: false, addPackage: '1', floorPlanImage: null, id: ''
      },
      floorPlanImageDropdown: [],
      unitConfigList: [],
      currPlayVideoUrl: null
    },
    buyerRegistrationData: {
      country: '', state: '', city: '', locality: '', interestedProperty: '', minBudget: '', maxBudget: '', InterestedIn: '', facingPref: '',
      wantToBuy: '', floorPref: ''
    },
    login_status: false,
    pageNotFoundPath: '',
    loading:false
  },
  reducers: {
    setuser(state, action) {
      state.userData = action.payload
      state.login_status = true
    },
    logout(state, action) {
      state.userData = {}
      state.login_status = false;
    },
    setlocation(state, action) {
      state.currLocation = action.payload
    },
    setPropertyStatus(state, action) {
      state.propertyStatus = action.payload
    },
    setPropertyListState(state, action) {
      state.propertyListState = action.payload
    },
    setFileterMenus(state, action) {
      state.filterMenus = action.payload
    },
    setCurrPage(state, action) {
      state.currPage = action.payload
    },
    setPageRefress(state, action) {
      state.pageRefresh = action.payload
    },
    setOutsideFilterState(state, action) {
      state.outSideFilterState = action.payload
    },
    setBuilderSearchStatus(state, action) {
      state.builderSearchStatus = action.payload
    },
    setPageNotFoundPath(state, action) {
      state.builderSearchStatus = action.payload
    },
    setPostPropertyFormData(state, action) {
      state.postPropertyFormData = action.payload
    },
    setBuyerRegData(state, action) {
      state.buyerRegistrationData = action.payload
    },
    setUserData(state, action) {
      state.userData = action.payload
    },
    setPackageDetails(state, action) {
      state.packageDetails = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setGettingListPageUrlData(state, action) {
      state.gettingListPageUrlData = action.payload
    },
  }
})
export const { setuser, logout, setlocation, setPropertyListState, setFileterMenus,
  setCurrPage, setPageRefress, setOutsideFilterState, setBuilderSearchStatus, setPageNotFoundPath,
  setPostPropertyFormData, setBuyerRegData, setUserData, setPackageDetails,setLoading,setGettingListPageUrlData } = UserSlice.actions;

export default UserSlice.reducer;