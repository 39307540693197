import React, { useEffect, useState } from 'react';
import { styles } from '../../Styles/Styles';
import Header from '../../components/Header/Header';
import FormCatagories from '../../components/PostProperty/PostPropertyComp';
import PropertyInfo from '../../components/PostProperty/PropertyInfo';
import Amenities from '../../components/PostProperty/Amenities';
import NearbyPlaces from '../../components/PostProperty/NearbyPlaces';
import Gallery from '../../components/PostProperty/Gallery';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import GeneralInfo from '../../components/PostProperty/GeneralInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setPostPropertyFormData } from '../../Redux/reducer/User';
import { useLocation } from 'react-router-dom';
import { UseApi } from '../../ApiConf';
// import { toast } from 'react-toastify';
import loader from '../../assets/Icons/loader.gif';
import { ToastContainer } from 'react-toastify';


const catagories = ['Property Info', 'Amenities', 'Nearby Places', 'Gallery'];


const PostProperty = () => {
    const { login_status, postPropertyFormData, userData, pageRefresh,loading } = useSelector(state => state.User);
    const [currCatagory, setCurrCategory] = useState(login_status ? 'Property Info' : 'General Info');
    const dispatch = useDispatch();
    const { Request } = UseApi();
    const currPath = useLocation();

    useEffect(() => {
        console.log('currPath...', currPath);
        let arr = currPath.pathname.split('/');
        console.log('arr...currpath..', arr);
        // if (!pageRefresh) {
        if (login_status) {
            if (currPath.pathname.includes('/dashboard/add-property/')) {
                getPropertyData(arr[3]);
            }
            if (arr.length == 3 && arr[arr.length - 1] == 'featured') {
                console.log('arr[arr.length - 1]....', arr[arr.length - 1]);
                dispatch(setPostPropertyFormData({ ...postPropertyFormData, type: 'featured', currPropId: '', generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: true } }));
                setCurrCategory('Property Info');
            } else if (arr.length == 2 && arr[arr.length - 1] == 'post-property') {
                dispatch(setPostPropertyFormData({
                    ...postPropertyFormData, type: '', currPropId: '',
                    generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: true },
                    propertyInfo: { ...postPropertyFormData.propertyInfo, completed: false },
                    amenities: { ...postPropertyFormData.amenities, completed: false },
                    nearByPlace: { ...postPropertyFormData.nearByPlace, completed: false },
                }));
                setCurrCategory('Property Info');
            }
        } else {
            dispatch(setPostPropertyFormData({
                ...postPropertyFormData, generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: false },
                propertyInfo: { ...postPropertyFormData.propertyInfo, completed: false },
                amenities: { ...postPropertyFormData.amenities, completed: false },
                nearByPlace: { ...postPropertyFormData.nearByPlace, completed: false },
            }));
            setCurrCategory('General Info');
        }
        // }
    }, [currPath, login_status]);


    // useEffect(() => {
    //     if (login_status) {
    //         dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: true } }));
    //         setCurrCategory('Property Info');
    //     } else {
    //         dispatch(setPostPropertyFormData({ ...postPropertyFormData, generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: false } }));
    //         setCurrCategory('General Info');
    //     }
    // }, [login_status])

    const getPropertyData = async (propId) => {
        //    if(checkInputValidation()) return;
        let token = localStorage.getItem('token');
        let inputdata = {
            property_id: propId,
            token: token,
        }
        let data;
        try {
            data = await Request('get-property-data', 'POST', inputdata);
        } catch (err) {
            console.log(err);
            // if (err?.message) {
            //     setInputErrorStatus(pre => ({ ...pre, Otp: err.message }));
            // }
        }
        console.log('result...', data);
        if (data?.statusCode == 200) {
            if (data.success) {
                // navigate('/dashboard/add-property/id');
                let propData = data.data;
                let amenities = {};
                let furnishing = {};
                if (propData.Amenities) {
                    propData.Amenities.split(',').forEach((item) => {
                        if (item) {
                            amenities[item] = true
                        }
                    });
                }
                if (propData.FurnishingItem) {
                    propData.FurnishingItem.split(',').forEach((item) => {
                        if (item) {
                            furnishing[item] = true
                        }
                    });
                }
                dispatch(setPostPropertyFormData({
                    ...postPropertyFormData,
                    currPropId: propData.ID,
                    PlanStatus: propData.PlanStatus,
                    currency: propData.CurrencySymbol,
                    generalInfo: { name: '', email: '', countryCode: '+91', mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: true },
                    // generalInfo: { name: propData.Name, email: propData.Email, countryCode: `+${propData.Country}`, mobileNum: '', Iam: 'Individual', Otp: '', mobileVerification: false, completed: false },
                    type: propData.Featured == 'Y' ? 'featured' : '',
                    propertyInfo: {
                        ...postPropertyFormData.propertyInfo,
                        listedFor: propData.ListedFor ? propData.ListedFor : 'Sale', propertyType: propData.PropertySubTypeId, country: propData.Country, countryName: propData.CountryName, state: propData.State, stateName: propData.StateName,
                        city: propData.City, locality: propData.Locality, subLocality: propData.Landmark, otherSubLocality: '', projectName: propData.ProjectName, otherProject: '', AgeOfProperty: propData.AgeOfProperty,
                        bedrooms: propData.Bedrooms, bathrooms: propData.Bathrooms, balcony: propData.Balconies, propertyOnFloor: propData.PropertyOnFloor, noOfFloor: propData.TotalFloor, buildUpArea: propData.BuiltUpArea, buildUpUnit: propData.BuildUnitMeasurment, carpetArea: propData.CarpetArea, carpetUnit: propData.CarpetUnitMeasurment,
                        propertyStatus: propData.Availability, availableFor: propData.AvailableFor, month: propData.pass_month, year: propData.pass_year, landMonth: propData.pass_month, landYear: propData.pass_year, reraId: propData.propertyRera, totalArea: propData.TotalArea, totalAreaUnit: propData.TotalUnitMeasurment, lengthOfPlot: propData.LengthOfPlot, lengthOfPlotUnit: propData.LengthUnit,
                        breadthOfPlot: propData.BreadthOfPlot, breadthOfPlotUnit: propData.BreadthUnit, FloorAllowed: propData.FloorAllowed, mainImage: propData.img_cover, completed: true
                    },
                    amenities: {
                        completed: true, salePrice: propData.ExpectedPrice, pricePerUnit: propData.PricePerUnit, isPriceNegotiable: propData.PriceNegotiable ? propData.PriceNegotiable : 'N', facing: propData.Facing ? propData.Facing : '', ownership: propData.Ownership ? propData.Ownership : '', parking: propData.ReservedParking ? propData.ReservedParking : '', flooringType: propData.Flooring ? propData.Flooring : '', view: propData.ViewSide, furnishingStatus: propData.Furnishing ? propData.Furnishing : '',
                        aminities: amenities, adPackage: propData.temp_plan_id, aboutProperty: propData.Description, aboutBuilder: propData.PropAboutBuilder, embadedLocation: propData.EmbeddedLocation, ExpectedPriceRent: '', ElectricityChargeIncluded: propData.ElectricityChargeIncluded, Maintenance: propData.Maintenance ? propData.Maintenance : '', SecurityDeposite: propData.SecurityDeposite ? propData.SecurityDeposite : '', MaintenanceType: propData.MaintenanceType ? propData.MaintenanceType : '',
                        DurationOfRentAgreement: propData.DurationOfRentAgreement ? propData.DurationOfRentAgreement : '', MonthOfNotice: propData.MonthOfNotice ? propData.MonthOfNotice : '', furnishingItems: furnishing, address: propData.LocalityName + ', ' + propData.CityName + ', ' + propData.StateName + ', ' + propData.CountryName
                    },
                    nearByPlace: {
                        completed: true, busStation: '', railwayMetro: '', airport: '', hospital: '', worship: '', atm: '', school: '', mall: '', mainRoad: '', highway: '', expressWay: '',
                        bank: '', publicGarden: '', amusementPark: '', milkDiary: '', movieTheatre: '', restaurant: '', library: '', swimmingPool: '', petrolGasPump: '', motorGarage: '', carChargingPoint: '',
                        spaBeautySalon: '', pharmacy: ''
                    },
                    buyRentGallary: { completed: false, imageUrls: propData.img_gallary_arr, imgSrcs: [], imageFiles: [], video: propData.video_url },
                    // newProjectGallery: { completed: false },
                    // newProjectInfo: {
                    //     completed: false, country: '', state: '', city: '', locality: '', subLocality: '', societyName: '', propertyType: '', bedroomFrom: '', bedRoomTo: '',
                    //     bathroomsFrom: '', bathroomsTo: '', totalFloor: '', amenities: '', furnishingType: '', furnishingItem: '', areaFrom: '', areaFromUnit: '', areaTo: '', areaToUnit: '',
                    //     priceFrom: '', priceTo: '', builderName: '', projectStaus: '', description: '', aboutBuilder: '', projectLink: '', image: '', video: ''
                    // },
                    // unitConfig: {
                    //     completed: false, unitFrom: '', unitTo: '', unitType: '', availability: '', builtUpAreaFrom: '', builtUpAreaTo: '', builtUpUnit: '', carpetAreaFrom: '', carpetAreaTo: '', carpetUnit: '',
                    //     minPrice: '', maxPrice: '', liveInTourVideo: null, makePayment: false, addPackage: '1', floorPlanImage: null
                    // },
                    // unitConfigList: [],
                    // currPlayVideoUrl: null
                }));
                if (currCatagory == 'General Info') {
                    setCurrCategory('Property Info');
                }
                // setCurrCategory('Nearby Places');
                // window.scrollTo({ top: 0, });
            } else {
                // console.log(data?.message)
                // toast(data.message, { type: 'error' });
            }
        }

    }

    const changeCategories = (catetory) => {
        setCurrCategory(catetory);
    }

    return (
        <div>
            <Header hideTostContainer={true} />
            <ToastContainer toastClassName={{}} />
            <div className='mt-[80px]'>
                <div className={styles.postpropTitle}>Post your Property</div>
                {loading && <div className="fixed top-[200px] z-50 right-1/2 flex justify-center items-center">
                    <img alt="Please wait.." title="Please wait.." src={loader} />
                </div>}
                <div className='mt-5 px-2 container mx-auto'>
                    <FormCatagories catagories={login_status ? catagories : ['General Info', ...catagories]} activeCatagory={currCatagory} onClickItem={(item) => setCurrCategory(item)} />
                    <div className={styles.formCard}>
                        {currCatagory === 'General Info' && <GeneralInfo setCurrCategory={changeCategories} />}
                        {currCatagory === 'Property Info' && <PropertyInfo setCurrCategory={changeCategories} />}
                        {currCatagory === 'Amenities' && <Amenities setCurrCategory={changeCategories} />}
                        {currCatagory === 'Nearby Places' && <NearbyPlaces setCurrCategory={changeCategories} />}
                        {currCatagory === 'Gallery' && <Gallery setCurrCategory={changeCategories} />}
                    </div>
                </div>
            </div>
            <TopCItiesFilter />
            <Footer />
        </div>
    );
}

export default PostProperty;
