import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import BgImage from '../../assets/images/buildersBg.jpg'
import { NavLink } from 'react-router-dom';
import TopCItiesFilter from '../../components/TopCItiesFilter';
import Footer from '../../components/Footer';
import { UseApi } from '../../ApiConf';
import loader from '../../assets/Icons/loader.gif';
import MetaTags from '../../components/MetaTags';

const PrivacyPolicy = () => {
  const { FetchData } = UseApi();
  const [loading, setLoading] = useState(true);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [meta,setMeta] = useState(null);

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  const getPrivacyPolicy = async () => {
    setLoading(true);
    let data;
    try {
      data = await FetchData(`statics?type=3&alias=privacy-policy`, 'GET');
    } catch (err) {
      console.log(err);
    }
    if (data) {
      console.log('privacy data..', data);
      setPrivacyPolicy(data.data);
      setMeta(data.meta);
    }
    setLoading(false);
  }
  return (
    <div className='container mx-auto'>
      <Header />
      {meta && <MetaTags metaData={meta}/>}
      <div>
        <div className=' fixed left-0 top-0 h-screen w-screen'>
          <img alt='' src={BgImage} className='h-full' />
        </div>
        {loading && <div className="fixed top-[300px] z-50 right-1/2 flex justify-center items-center">
          <img alt="Please wait.." title="Please wait.." src={loader} />
        </div>}
        <div className='fixed left-0 h-full mb-2 w-full bg-black bg-opacity-50 overflow-y-scroll'>
          <div>
            <div className='mt-[150px] mb-10 h-[100px] z-[200] text-center text-white tracking-widest'>
              <p className={'text-center text-2xl sm:text-3xl text-white font-semibold tracking-wider md:text-3xl'}>Privacy Policy</p>
              <div className='mt-5 text-xs lg:text-sm'>
                <NavLink to={'/'}>HOME</NavLink> / <span>PRIVACY POLICY</span>
              </div>
            </div>
            <div className='pt-5 min-h-[500px] bg-white'>
              <div className='px-2 sm:px-[5%] w-full'>
                {/* <b className={styles.title4 + 'tracking-wide '}>Privacy Policy</b> */}
                <div className='mt-5 prose prose-sm sm:prose-base prose-p:my-0 min-w-full text-justify' dangerouslySetInnerHTML={{ __html: privacyPolicy?.Content }} />
              </div>
              <TopCItiesFilter />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
