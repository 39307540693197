import React, { useEffect, useState } from 'react';
import { CategoryTitle, DropdownInput, InputList } from './PostPropertyComp';
import { styles } from '../../Styles/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { setPostPropertyFormData } from '../../Redux/reducer/User';
import { toast } from 'react-toastify';

import { LoadScript, GoogleMap, Autocomplete, Marker } from '@react-google-maps/api';
import NearByLocation from '../NearByLocation';
// import { usePlacesAutocomplete } from '@react-google-maps/api';

// const inputs = [
//     { name: 'Bus Station', placeholder: 'Eg. 1KM or 100M', state: 'busStatus', star: false, dropdownData: null },
//     { name: 'Railway Metro Station', placeholder: 'Eg. 1KM or 100M', state: 'metro', star: false, dropdownData: null },
//     { name: 'Airport', placeholder: 'Eg. 1KM or 100M', state: 'airport', star: false, dropdownData: null },
//     { name: 'Hospital', placeholder: 'Eg. 1KM or 100M', state: 'Hospital', star: false, dropdownData: null },
//     { name: 'Worship', placeholder: 'Eg. 1KM or 100M', state: 'Workship', star: false, dropdownData: null },
//     { name: 'ATM', placeholder: 'Eg. 1KM or 100M', state: 'ATM', star: false, dropdownData: null },
//     { name: 'School', placeholder: 'Eg. 1KM or 100M', state: 'School', star: false, dropdownData: null },
//     { name: 'Shopping Mall', placeholder: 'Eg. 1KM or 100M', state: 'ShoppingMall', star: false, dropdownData: null },
//     { name: 'Main Road', placeholder: 'Eg. 1KM or 100M', state: 'Main Road', star: false, dropdownData: null },
//     { name: 'State Highway', placeholder: 'Eg. 1KM or 100M', state: 'State Highway', star: false, dropdownData: null },
//     { name: 'Expressway', placeholder: 'Eg. 1KM or 100M', state: 'Expressway', star: false, dropdownData: null },
//     { name: 'Bank', placeholder: 'Eg. 1KM or 100M', state: 'Bank', star: false, dropdownData: null },
//     { name: 'Public Garden', placeholder: 'Eg. 1KM or 100M', state: 'Public Garden', star: false, dropdownData: null },
//     { name: 'Amusement Park', placeholder: 'Eg. 1KM or 100M', state: 'Amusement Park', star: false, dropdownData: null },
//     { name: 'Milk Dairy', placeholder: 'Eg. 1KM or 100M', state: 'Milk Dairy', star: false, dropdownData: null },
//     { name: 'Movie Theatre', placeholder: 'Eg. 1KM or 100M', state: 'Movie Theatre', star: false, dropdownData: null },
//     { name: 'Restaurant', placeholder: 'Eg. 1KM or 100M', state: 'Restaurant', star: false, dropdownData: null },
//     { name: 'Library', placeholder: 'Eg. 1KM or 100M', state: 'Library', star: false, dropdownData: null },
//     { name: 'Public Swimming Pool', placeholder: 'Eg. 1KM or 100M', state: 'Public Swimming Pool', star: false, dropdownData: null },
//     { name: 'Petrol Gas Pump', placeholder: 'Eg. 1KM or 100M', state: 'Petrol Gas Pump', star: false, dropdownData: null },
//     { name: 'Motor Garage', placeholder: 'Eg. 1KM or 100M', state: 'Motor Garage', star: false, dropdownData: null },
//     { name: 'Car Charging Point', placeholder: 'Eg. 1KM or 100M', state: 'Car Charging Point', star: false, dropdownData: null },
//     { name: 'Spa Beauty Salon', placeholder: 'Eg. 1KM or 100M', state: 'Spa Beauty Salon', star: false, dropdownData: null },
//     { name: 'Pharmacy', placeholder: 'Eg. 1KM or 100M', state: 'Pharmacy', star: false, dropdownData: null },
// ]
const nearByPlacesType = [
    { label: 'Bus Stand', value: 'bus_station' },
    { label: 'School', value: 'school' },
    { label: 'Hospital', value: 'hospital' },
    { label: 'ATM', value: 'atm' },
    { label: 'Bank', value: 'bank' },
    { label: 'Park', value: 'park' },
    { label: 'Pharmacy', value: 'pharmacy' },
    // { label: 'Salon', value: 'salon' },
    { label: 'Restaurant', value: 'restaurant' },
    { label: 'Airport', value: 'airport' },
];

const NearbyPlaces = ({ setCurrCategory }) => {
    const [animation, setAnimation] = useState(false);
    const { postPropertyFormData } = useSelector(state => state.User);
    const [inputErrStatus, setInputErrStatus] = useState({});
    const [nearByType, setNearByType] = useState({ label: '', value: '' });
    const [localityName, setLocalityName] = useState('Madhuranthagam');
    const dispatch = useDispatch();

    const [autocomplete, setAutocomplete] = useState(null);
    const [place, setPlace] = useState(null);
    const [placeId, setPlaceId] = useState(null);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [nearbyPlaces, setNearbyPlaces] = useState([]);
    const [googleLoaded, setGoogleLoaded] = useState(false);


    // const handlePlaceSelect = () => {
    //     // Access the selected place object and extract its properties as needed
    //     console.log('Selected place:', place);
    // };

    // const onAutocompleteLoad = (autocomplete) => {
    //     setAutocomplete(autocomplete);
    // };

    // const onPlaceChanged = () => {
    //     if (autocomplete !== null) {
    //         const place = autocomplete.getPlace();
    //         setPlace(place);
    //     } else {
    //         console.log('Autocomplete is not loaded yet!');
    //     }
    // };
    useEffect(() => {
        setAnimation(true);
    }, [])

    const onClickSave = () => {
        setCurrCategory('Gallery');
        dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, completed: true } }));
        // toast('Near by places saved successfully !', { type: 'success' });
        window.scrollTo({ top: 0 });
    }

    // const fetchPlaceId = (address) => {
    //     const geocoder = new window.google.maps.Geocoder();
    //     // const geoco = new window.google.maps.places.AutocompleteService();
    //     console.log('address...', address);
    //     console.log('geocoder2...', geocoder);
    //     geocoder.geocode({ address: address }, (results, status) => {
    //         if (status === 'OK' && results.length > 0) {
    //             console.log('results[0].place_id...', results[0].place_id);
    //             if (results[0].geometry.location) {
    //                 setCenter({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
    //                 console.log('results[0].geometry.location...', results[0].geometry.location.lat(), results[0].geometry.location.lng());
    //             }
    //             const placeId = results[0].place_id;
    //             setPlaceId(placeId);
    //         } else {
    //             console.error('Geocode was not successful for the following reason:', status);
    //         }
    //     });
    // };



    // useEffect(() => {
    //     fetchCoordinates(postPropertyFormData.amenities.address);
    // }, []);
    // const fetchCoordinates = async (address) => {
    //     try {
    //         const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA`);
    //         const data = await response.json();
    //         console.log('fetchcordinates data...', data);
    //         if (data.results && data.results.length > 0) {
    //             const { lat, lng } = data.results[0].geometry.location;
    //             setCenter({ lat, lng });
    //             performNearbySearch(data.results[0].geometry.location, nearByType);
    //             // fetchNearbyPlaces(data.results[0].geometry.location);
    //         } else {
    //             console.error('No results found');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching coordinates:', error);
    //     }
    // };

        
    // const performNearbySearch = (location, nearbyloc) => {
    //     console.log('nearbyloc.....',nearbyloc);
    //     // if (!googleLoaded) return;
    //     let mainLocation = location ? location : center;
    //     const request = {
    //         location: mainLocation,
    //         radius: 5000, // Specify the radius in meters (e.g., 5000 meters)
    //         // type: nearbyloc.value ? nearbyloc.value : nearByType.value // Specify the type of place you want to search for
    //         type: nearbyloc.value
    //         // type: 'hospital' // Specify the type of place you want to search for
    //     };

    //     const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    //     service.nearbySearch(request, (results, status) => {
    //         if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    //             const placesWithDistance = results.map(place => {
    //                 // const Location = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
    //                 // const distance = window.google.maps.geometry.spherical.computeDistanceBetween(mainLocation, Location);
    //                 // const Location = item.geometry.location;
    //                 const distance = calculateDistance(place.geometry.location,mainLocation);
    //                 return { ...place, distance };
    //             });
    //             const sortedPlaces = placesWithDistance.sort((a, b) => a.distance - b.distance);
    //             setNearbyPlaces(sortedPlaces);
    //             console.log('nearby results...', results);
    //         } else {
    //             console.error('Nearby search failed:', status);
    //         }
    //     });
    // };

    // const calculateDistance = (location,mainLocation) => {
    //     let mainLoc = mainLocation?mainLocation:center;
    //     let lat1 = location.lat();
    //     let lon1 = location.lng();
    //     let lat2 = mainLoc.lat;
    //     let lon2 = mainLoc.lng;
    //     const R = 6371; // Radius of the Earth in kilometers
    //     const dLat = (lat2 - lat1) * (Math.PI / 180); // Convert degrees to radians
    //     const dLon = (lon2 - lon1) * (Math.PI / 180); // Convert degrees to radians
    //     const a =
    //         Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    //         Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    //     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    //     const distance = R * c; // Distance in kilometers
    //     return distance.toFixed(2);
    // };


    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA&libraries=places`;
    //     script.async = true;
    //     script.onload = () => {
    //       setGoogleLoaded(true);
    //     };
    //     document.body.appendChild(script);

    //     // return () => {
    //     //   document.body.removeChild(script);
    //     // };
    //     return () => {
    //         if (script.parentNode) {
    //           script.parentNode.removeChild(script);
    //         }
    //       };
    //   }, []);


    // useEffect(() => {
    //     performNearbySearch();
    // }, [center,googleLoaded]);

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA&libraries=places`;
    //     script.async = true;
    //     script.defer = true;
    //     script.onload = () => {
    //         // fetchPlaceId(`${localityName},Kolkata,West Bengal,India`); // Replace with your address
    //         fetchPlaceId(postPropertyFormData.amenities.address); // Replace with your address
    //         // fetchPlaceId(`Adambakkam, Chennai, Tamil Nadu, India`); // Replace with your address
    //     };
    //     document.body.appendChild(script);
    //     // Cleanup: remove the script when the component unmounts
    //     return () => {
    //         if (document.body.contains(script)) {
    //             document.body.removeChild(script);
    //         }
    //     };
    // }, []);

    // const fetchNearbyPlaces = async (location,nearbyloc) => {
    //     let type = nearbyloc ? nearbyloc : nearByType.value
    //     try {
    //         const response = await fetch(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${location.lat},${location.lng}&radius=5000&type=${type}&key=AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA`);
    //         const data = await response.json();
    //         console.log('data.results...',data.results);
    //         // setPlaces(data.results);
    //         // setLoading(false);
    //     } catch (error) {
    //         console.error('Error fetching nearby places:', error);
    //         // setLoading(false);
    //     }
    // };

   

   


    return (
        <div className={'mt-16 ' + (animation ? ' transition-transform ease-in-out transform -translate-y-10 duration-1000 ' : '')}>
            {(postPropertyFormData.generalInfo.completed && postPropertyFormData.propertyInfo.completed && postPropertyFormData.amenities.completed) ? <>
                <CategoryTitle title={'Nearby Places :'} icon={'fa fa-house'} />
               
                {/* <LoadScript
                    libraries={["places"]}
                    googleMapsApiKey="AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA">
                    <GoogleMap
                        id="autocomplete-map"
                        mapContainerStyle={{
                            width: '100%',
                            height: '400px'
                        }}
                        zoom={15}

                        center={center}
                    >
                        <Marker position={center} />
                    </GoogleMap>
                </LoadScript> */}
                {/* <iframe
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyDPTHOYE5ZFGDIYxVsiJmOwMn9sHx0iYQA&center=${22.5726},${88.3639}&zoom=15`}
                    allowFullScreen
                ></iframe> */}

                  <NearByLocation address={postPropertyFormData.amenities.address} showMap={true}/>
              
                {/* <div className='mt-10'>
                    <div className={'mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'}>
                        <div className={''}>
                            <span className={styles.textMedium}>Search Nearby Places</span>
                            <select name="" className={styles.input + 'mt-1 text-gray-500 '} value={JSON.stringify(nearByType)} onChange={(e) => {
                                let item = JSON.parse(e.target.value);
                                setNearByType(item);
                                performNearbySearch(center, item);
                            }}>
                                <option value={JSON.stringify({label:'',value:''})}>Select NearBy Type</option>
                                {nearByPlacesType.map((item, index) => {
                                    return (
                                        <option key={index} className='text-sm sm:text-base text-gray-500' value={JSON.stringify(item)}>{item.label}</option>
                                    )
                                })}
                            </select>
                        </div>

                    </div>
                    <div className='mt-5 lg:w-[80%]'>
                        <p className={styles.title4}>{nearByType.label} {nearByType.label?'near':'Near'} to {localityName}</p>
                        {nearbyPlaces.map((item, index) => {
                            return (
                                <div className='grid grid-cols-2 gap-5 mt-2  px-2 border-b-[1px] border-b-gray-200'>
                                    <div>{item.name}</div>
                                    <div className='text-right'>{item.distance} KM</div>
                                </div>
                            )
                        })}
                    </div>
                </div> */}

                {/* <div className={'mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3'}>
                    <DropdownInput title={'Bus Station'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.busStation}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, busStation: e.target.value } }));
                            if (inputErrStatus.busStation) { setInputErrStatus(pre => ({ ...pre, busStation: '' })) };
                        }}
                    />
                    <DropdownInput title={'Railway Metro Station'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.railwayMetro}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, railwayMetro: e.target.value } }));
                            if (inputErrStatus.railwayMetro) { setInputErrStatus(pre => ({ ...pre, railwayMetro: '' })) };
                        }}
                    />
                    <DropdownInput title={'Airport'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.airport}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, airport: e.target.value } }));
                            if (inputErrStatus.airport) { setInputErrStatus(pre => ({ ...pre, airport: '' })) };
                        }}
                    />
                    <DropdownInput title={'Hospital'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.hospital}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, hospital: e.target.value } }));
                            if (inputErrStatus.hospital) { setInputErrStatus(pre => ({ ...pre, hospital: '' })) };
                        }}
                    />
                    <DropdownInput title={'Worship'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.worship}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, worship: e.target.value } }));
                            if (inputErrStatus.worship) { setInputErrStatus(pre => ({ ...pre, worship: '' })) };
                        }}
                    />
                    <DropdownInput title={'ATM'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.atm}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, atm: e.target.value } }));
                            if (inputErrStatus.atm) { setInputErrStatus(pre => ({ ...pre, atm: '' })) };
                        }}
                    />
                    <DropdownInput title={'School'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.school}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, school: e.target.value } }));
                            if (inputErrStatus.school) { setInputErrStatus(pre => ({ ...pre, school: '' })) };
                        }}
                    />
                    <DropdownInput title={'Shopping Mall'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.mall}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, mall: e.target.value } }));
                            if (inputErrStatus.mall) { setInputErrStatus(pre => ({ ...pre, mall: '' })) };
                        }}
                    />
                    <DropdownInput title={'Main Road'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.mainRoad}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, mainRoad: e.target.value } }));
                            if (inputErrStatus.mainRoad) { setInputErrStatus(pre => ({ ...pre, mainRoad: '' })) };
                        }}
                    />
                    <DropdownInput title={'State Highway'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.highway}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, highway: e.target.value } }));
                            if (inputErrStatus.highway) { setInputErrStatus(pre => ({ ...pre, highway: '' })) };
                        }}
                    />
                    <DropdownInput title={'Expressway'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.expressWay}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, expressWay: e.target.value } }));
                            if (inputErrStatus.expressWay) { setInputErrStatus(pre => ({ ...pre, expressWay: '' })) };
                        }}
                    />
                    <DropdownInput title={'Bank'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.bank}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, bank: e.target.value } }));
                            if (inputErrStatus.bank) { setInputErrStatus(pre => ({ ...pre, bank: '' })) };
                        }}
                    />
                    <DropdownInput title={'Public Garden'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.publicGarden}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, publicGarden: e.target.value } }));
                            if (inputErrStatus.publicGarden) { setInputErrStatus(pre => ({ ...pre, publicGarden: '' })) };
                        }}
                    />
                    <DropdownInput title={'Amusement Park'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.amusementPark}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, amusementPark: e.target.value } }));
                            if (inputErrStatus.amusementPark) { setInputErrStatus(pre => ({ ...pre, amusementPark: '' })) };
                        }}
                    />
                    <DropdownInput title={'Milk Dairy'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.milkDiary}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, milkDiary: e.target.value } }));
                            if (inputErrStatus.milkDiary) { setInputErrStatus(pre => ({ ...pre, milkDiary: '' })) };
                        }}
                    />
                    <DropdownInput title={'Movie Theatre'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.movieTheatre}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, movieTheatre: e.target.value } }));
                            if (inputErrStatus.movieTheatre) { setInputErrStatus(pre => ({ ...pre, movieTheatre: '' })) };
                        }}
                    />
                    <DropdownInput title={'Restaurant'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.restaurant}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, restaurant: e.target.value } }));
                            if (inputErrStatus.restaurant) { setInputErrStatus(pre => ({ ...pre, restaurant: '' })) };
                        }}
                    />
                    <DropdownInput title={'Library'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.library}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, library: e.target.value } }));
                            if (inputErrStatus.library) { setInputErrStatus(pre => ({ ...pre, library: '' })) };
                        }}
                    />
                    <DropdownInput title={'Public Swimming Pool'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.swimmingPool}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, swimmingPool: e.target.value } }));
                            if (inputErrStatus.swimmingPool) { setInputErrStatus(pre => ({ ...pre, swimmingPool: '' })) };
                        }}
                    />
                    <DropdownInput title={'Petrol Gas Pump'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.petrolGasPump}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, petrolGasPump: e.target.value } }));
                            if (inputErrStatus.petrolGasPump) { setInputErrStatus(pre => ({ ...pre, petrolGasPump: '' })) };
                        }}
                    />
                    <DropdownInput title={'Motor Garage'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.motorGarage}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, motorGarage: e.target.value } }));
                            if (inputErrStatus.motorGarage) { setInputErrStatus(pre => ({ ...pre, motorGarage: '' })) };
                        }}
                    />
                    <DropdownInput title={'Car Charging Point'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.carChargingPoint}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, carChargingPoint: e.target.value } }));
                            if (inputErrStatus.carChargingPoint) { setInputErrStatus(pre => ({ ...pre, carChargingPoint: '' })) };
                        }}
                    />
                    <DropdownInput title={'Spa Beauty Salon'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.spaBeautySalon}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, spaBeautySalon: e.target.value } }));
                            if (inputErrStatus.spaBeautySalon) { setInputErrStatus(pre => ({ ...pre, spaBeautySalon: '' })) };
                        }}
                    />
                    <DropdownInput title={'Pharmacy'} placeholder={'Eg. 1KM or 100M'} required={false}
                        value={postPropertyFormData.nearByPlace.pharmacy}
                        onChange={(e) => {
                            dispatch(setPostPropertyFormData({ ...postPropertyFormData, nearByPlace: { ...postPropertyFormData.nearByPlace, pharmacy: e.target.value } }));
                            if (inputErrStatus.pharmacy) { setInputErrStatus(pre => ({ ...pre, pharmacy: '' })) };
                        }}
                    />
                </div> */}
                <div className='my-7 flex gap-5'>
                    <button className={styles.formBtn}
                        onClick={() => {
                            setCurrCategory('Amenities');
                            window.scrollTo({ top: 0 });
                        }} >Back</button>
                    <button type='submit' className={styles.formBtn} onClick={onClickSave}>Save & Next</button>
                </div>
            </>
                :
                <div className={(animation ? 'transition-opacity opacity-100 duration-500' : 'opacity-0') + ' bg-red-600 rounded text-white p-2 font-semibold'}>
                    Please submit {!postPropertyFormData.generalInfo.completed ? 'general information' : !postPropertyFormData.propertyInfo.completed ? 'property information' : 'aminities'}  first
                </div>
            }
        </div>
    );
}


export default NearbyPlaces;
